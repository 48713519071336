export const updateForumRules = (inputs) => {
    return {
        id: {
            errors: [],
            value: inputs.id,
            isValid: null,
            validations: {
                required: {
                    error: "Forum Id is required",
                    trim: true
                },
                maxLength: {
                    value: 100,
                    error: "Forum Id cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Forum Id invalid"
                // }
            }
        },
        description: {
            errors: [],
            value: inputs.description,
            isValid: null,
            validations: {
                maxLength: {
                    value: 300,
                    error: "Description cannot be more than 300 characters"
                }
            }
        },
        title: {
            errors: [],
            value: inputs.title,
            isValid: null,
            validations: {
                required: {
                    error: "Title is required",
                    trim: true
                },
                maxLength: {
                    value: 300,
                    error: "Title cannot be more than 300 characters"
                }
            }
        },
    }
}