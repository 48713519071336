//CDC, FDA
import { Utils } from "../../utils/index"

const RssFeedsLimit = 5;

export const sources = [{
    name: "Centers for Disease Control and Prevention (CDC)",
    url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCiMg06DjcUk5FRiM3g5sqoQ",
    get: (rssFeeds) => {
        let entries = rssFeeds?.feed?.entry ?? [];
        Utils.shuffle(entries);
        let list = [];
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            const link = entry?.link?._attributes?.href ?? "";
            const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
            const author = entry?.author?.name?._text ?? "";
            const title = entry?.title?._text ?? "";
            const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
            list.push({
                link,
                thumbnail_url,
                author,
                title,
                publish_date
            });
            if (list.length == RssFeedsLimit) {
                break;
            }
        }
        return list
    }
},{
    name: "American Heart Association",
    url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCLiWQk8JzhNRcNiDKk4dpaw",
    get: (rssFeeds) => {
        // console.log("rssFeeds", rssFeeds);
        let entries = rssFeeds?.feed?.entry ?? [];
        Utils.shuffle(entries);
        let list = [];
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            const link = entry?.link?._attributes?.href ?? "";
            const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
            const author = entry?.author?.name?._text ?? "";
            const title = entry?.title?._text ?? "";
            const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
            list.push({
                link,
                thumbnail_url,
                author,
                title,
                publish_date
            });
            if (list.length == RssFeedsLimit) {
                break;
            }
        }
        return list
    }
},{
    name: "Johns Hopkins Medicine",
    url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCATNzbTbfeoMhNonZGZmrhA",
    get: (rssFeeds) => {
        let entries = rssFeeds?.feed?.entry ?? [];
        Utils.shuffle(entries);
        let list = [];
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            const link = entry?.link?._attributes?.href ?? "";
            const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
            const author = entry?.author?.name?._text ?? "";
            const title = entry?.title?._text ?? "";
            const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
            list.push({
                link,
                thumbnail_url,
                author,
                title,
                publish_date
            });
            if (list.length == RssFeedsLimit) {
                break;
            }
        }
        return list
    }
},{
    name: "Cleveland Clinic",
    url: "https://www.youtube.com/feeds/videos.xml?channel_id=UCxyiSz4m161Z6frOsFxJpgw",
    get: (rssFeeds) => {
        let entries = rssFeeds?.feed?.entry ?? [];
        Utils.shuffle(entries);
        let list = [];
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            const link = entry?.link?._attributes?.href ?? "";
            const thumbnail_url = entry["media:group"]["media:thumbnail"]?._attributes?.url ?? "";
            const author = entry?.author?.name?._text ?? "";
            const title = entry?.title?._text ?? "";
            const publish_date = entry?.published?._text ?? ((new Date()).toDateString());
            list.push({
                link,
                thumbnail_url,
                author,
                title,
                publish_date
            });
            if (list.length == RssFeedsLimit) {
                break;
            }
        }
        return list
    }
}];