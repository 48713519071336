export const searchJobRules = (inputs) => {
    return {
        searchTerm: {
            errors: [],
            value: inputs.searchTerm,
            isValid: null,
            validations: {
                maxLength: {
                    value: 300,
                    error: "Search text cannot be more than 300 characters"
                }
            }
        },
        isOnSite: {
            errors: [],
            value: inputs.isOnSite,
            isValid: null,
            validations: {
                required: "On Site is required",
                inList: {
                    value: ["true", "false"],
                    error: "On site expected true or false"
                }
            }
        },
        isHybrid: {
            errors: [],
            value: inputs.isHybrid,
            isValid: null,
            validations: {
                required: "Hybrid is required",
                inList: {
                    value: ["true", "false"],
                    error: "Hybrid expected true or false"
                }
            }
        },
        isRemote: {
            errors: [],
            value: inputs.isRemote,
            isValid: null,
            validations: {
                required: "Remote is required",
                inList: {
                    value: ["true", "false"],
                    error: "Remote expected true or false"
                }
            }
        },
        isFulltime: {
            errors: [],
            value: inputs.isFulltime,
            isValid: null,
            validations: {
                required: "Fulltime is required",
                inList: {
                    value: ["true", "false"],
                    error: "Full time expected true or false"
                }
            }
        },
        isPartTime: {
            errors: [],
            value: inputs.isPartTime,
            isValid: null,
            validations: {
                required: "Part time is required",
                inList: {
                    value: ["true", "false"],
                    error: "Part time expected true or false"
                }
            }
        },
        isContract: {
            errors: [],
            value: inputs.isContract,
            isValid: null,
            validations: {
                required: "Contract is required",
                inList: {
                    value: ["true", "false"],
                    error: "Contract expected true or false"
                }
            }
        },
    }
}