import React from "react";
import ArrowLongLeftIcon from "../icons/ArrowLongLeft";
import ArrowLongRightIcon from "../icons/ArrowLongRight";
import { usePagination, DOTS } from './usePagination';
import classnames from 'classnames';


const Pagination = props => {

    const {
        id,
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
      } = props;
    
      const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
      });
    
      // If there are less than 2 times in pagination range we shall not render the component
      if (currentPage === 0 || paginationRange.length < 2) {
        return null;
      }
    
      const onNext = () => {
        onPageChange(currentPage + 1);
      };
    
      const onPrevious = () => {
        onPageChange(currentPage - 1);
      };
    
    let lastPage = paginationRange[paginationRange.length - 1];

    let leftArrowClasses = "inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ";
    let leftArrowTextColor = "";
    if(currentPage === 1){
        leftArrowTextColor = "text-gray-300";
        leftArrowClasses += " disabled pointer-events-none ";
    }else{
        leftArrowTextColor = "text-gray-500";
        leftArrowClasses += " hover:border-gray-300 hover:text-gray-700";
    }
    leftArrowClasses = `${leftArrowClasses} ${leftArrowTextColor}`;

    let rightArrowClasses = "inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium";
    let rightArrowTextColor = "";
    if(currentPage === lastPage){
        rightArrowTextColor = "text-gray-300";
        rightArrowClasses += " disabled pointer-events-none ";
    }else{
        rightArrowTextColor = "text-gray-500";
        rightArrowClasses += " hover:border-gray-300 hover:text-gray-700";
    }
    rightArrowClasses = `${rightArrowClasses} ${rightArrowTextColor}`;

    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            {/* Left navigation arrow */}
            <div className="-mt-px flex w-0 flex-1">
                <a
                    href="#"
                    className={classnames(leftArrowClasses, {disabled: currentPage === 1})}
                    onClick={onPrevious}
                >
                    <ArrowLongLeftIcon className={`mr-3 h-5 w-5 ${leftArrowTextColor}`} aria-hidden="true" />
                    Previous
                </a>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {paginationRange.map(pageNumber => {
                    // If the pageItem is a DOT, render the DOTS 
                    if (pageNumber === DOTS) {
                        return <span key={`${id}-dots-${pageNumber}`} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>;
                    }
                    // Render our Page Pills
                    let pillClass = "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium";
                    if(pageNumber === currentPage){
                        pillClass = `${pillClass} border-purple-900 text-purple-900`;
                    }else{
                        // normal pill
                        pillClass = `${pillClass} border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`;
                    }
                    return (
                        <a
                            key={`${id}-${pageNumber}`}
                            href="#"
                            className={pillClass}
                            aria-current="page"
                            onClick={() => onPageChange(pageNumber)}
                        >
                           {pageNumber}
                        </a>
                    );
                })}
            </div>
            {/*  Right Navigation arrow */}
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <a
                    href="#"
                    className={classnames(rightArrowClasses, {disabled: currentPage === 1})}
                    onClick={onNext}
                >
                    Next
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
            </div>
        </nav>
    );
};

export default Pagination;



