export const searchCommunityRules = (inputs) => {
    return {
        searchTerm: {
            errors: [],
            value: inputs.searchTerm,
            isValid: null,
            validations: {
                maxLength: {
                    value: 100,
                    error: "Search text cannot be more than 100 characters"
                }
            }
        }
    }
};