import React from "react";

const Ad = ({fillColor, className, strokeColor}) => {
    const fill = (fillColor)?fillColor:  "none";
    const stroke = (strokeColor)?strokeColor:  "currentColor";
    let classes = `w-4 h-4`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill={fill} 
            viewBox="0 0 24 24" 
            stroke-width="1.5" 
            stroke={stroke} 
            className={classes}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z" />
        </svg>
    );
}

export default Ad;




