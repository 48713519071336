import React, { useState, useEffect } from "react";
import MainLayout from "../components/layouts/MainLayout";

const PrivacyPolicy = () => {

    return (
        <MainLayout currentPage={"terms"}  >
            <article className="flex flex-col overflow-hidden text-sm bg-primary-white rounded-md">

                <header class="flex flex-col ">
                    <h1 class="text-lg px-8 pt-3 font-bold tracking-tight text-zinc-800 sm:text-lg">
                        Privacy Policy
                    </h1>
                </header>

                <div class="mt-1 px-8 pt-1 pb-5 prose flex flex-col gap-y-5 overflow-x-hidden ">
                    <p>
                        The suppliers (Wefayo, WEFAYO, We, Us, Our) of the service provided by this website (Service, SERVICE) are making available online features to its users (User, You, Author, Individual(s)).
                    </p>

                    <p>
                        <h3 className="font-bold mb-0">Data Collection</h3>
                        The Service collects only essential Registration Information for Individual accounts. It doesn't request or store details like Street Address, Marital Status, Gender, Date of Birth, or Medical Conditions, as these aren't necessary. Users can provide any name, as accuracy isn't required or verified.
                    </p>
                    <p>
                        <h3 className="font-bold">Data Sharing</h3>
                        We do not give, sell, or transfer any Registration Information provided by Users to third parties in a way that will certainly identify a specific person.
                    </p>

                    <p>
                        <h3 className="font-bold">Content</h3>
                        We are not responsible for any User posted, generated, uploaded, submitted, published, transmitted,
                        messaged (“Post”, “Posted”), information, data, text, images, audio, links, or other materials
                        ("Content"). The Content submitted expresses the views of their Author only. We are not obligated to
                        review, inspect, or act on the Content.
                    </p>
                    <p>
                        You are granting us a non-exclusive, permanent, irrevocable, unlimited license to use, publish, or re-
                        publish Content as long as it doesn’t, with certainty, identify a specific person. You retain ownership

                        over the Content.
                    </p>
                    <p>
                        <h3 className="font-bold">Privacy Measures</h3>
                        Wefayo’s policy is to take reasonable measures to respect the privacy of its Users. Wefayo will take
                        reasonable measures not to disclose information about You or the contents of your communications,
                        other than Content that is publicly available or accessible via the Service, except that We may do so in
                        the good faith belief that such action is reasonably necessary (a) to comply with the law; (b) to comply
                        with legal process; (c) to enforce its Terms and Conditions; (d) to respond to claims that any Content
                        violates the rights of third parties; or (e) to protect the interests of Wefayo or others.
                    </p>

                    <p>
                        <h3 className="font-bold">Terms and Conditions</h3>
                        By using or accessing the Service, You accept and agree to be bound by the following terms and
                        conditions. If you do not wish to be bound by them, then please do not accept these terms and
                        conditions, or use the Service.
                    </p>
                    <p>
                        You understand that all Content appearing in the Service is the sole responsibility of those persons
                        Posting it. This means that You, not Wefayo, are entirely responsible for all Content that You Post.
                        Wefayo does not control the content of anything Posted and does not guarantee the accuracy, integrity, or quality of anything on the Service or any products or services that may appear on it. Under no circumstances will Wefayo be liable for any errors or omissions in any Content or for any loss or damages of any kind incurred as a result of the use of any information contained in the Service.
                    </p>
                    <p>
                        You agree not to use the Service to submit or link to any Content which is defamatory, abusive, hateful,
                        threatening, spam or spam-like, likely to offend, contains pornographic or objectionable content,
                        contains personal information of others, risks copyright infringement, encourages unlawful activity, or
                        otherwise violates any United States laws.
                    </p>
                    <p>
                        All Content you Post may be reviewed by Staff members. All Content you Post may be sent to third-party
                        services (including, but not limited to, spam prevention services).
                    </p>
                    <p>
                        We reserve the right to remove or modify any Content Posted for any reason without explanation.
                        Requests for removing or modifying Content will be undertaken only at our discretion. We reserve the
                        right to act (including, but not limited to suspend, deactivate) against any account with the Service at
                        any time.
                    </p>
                    <p>
                        <span>
                            You agree not to do any of the following while using the Service:
                        </span>
                        <ul className="list-decimal pl-5">
                            <li>post, generate, upload, submit, publish, transmit, message (“Post”), information, data, text,
                                images, audio, links, or other materials ("Content"). that is unlawful, harmful, threatening,
                                abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, that may be invasive of
                                another's privacy, hateful, racially, ethnically or otherwise objectionable;
                            </li>
                            <li>
                                impersonate any person or entity, including but not limited to Us, moderator(s), or falsely state
                                or otherwise misrepresent your affiliation with a person or entity;
                            </li>
                            <li>
                                Post any Content that you do not have a right to under any law or under contractual or fiduciary
                                relationships (such as nondisclosure agreements);
                            </li>
                            <li>
                                Post any unsolicited advertising, promotional materials, "junk mail", "spam", "chain letters",
                                "pyramid schemes", or any other form of solicitation;
                            </li>
                            <li>
                                intentionally or unintentionally violate any applicable local, state, national or international law,
                                and any regulations having the force of law while using or accessing the Service; and
                            </li>
                            <li>
                                Post any Content that infringes any patent, trademark, trade secret, copyright or other
                                proprietary rights ("Rights") of any party. By Posting Content, you automatically grant, (or
                                warrant that the owner of such Content has expressly granted) the royalty-free, perpetual,
                                assignable and sublicensable right and license to use, reproduce, modify, adapt, publish,
                                translate, create derivative works from, distribute, perform and display such Content (in whole
                                or part) worldwide and/or to incorporate it in other works in any form, media, or technology
                                now known or later developed.
                            </li>
                        </ul>
                    </p>
                    <p>
                        Wefayo and its designees shall have the right in their sole discretion to edit, refuse to post, or remove
                        any Content Posted, or move it to other areas of the Service. Without limiting the foregoing, Wefayo
                        and its designees shall have the right to remove any Content that violates the provisions hereof or is
                        otherwise objectionable. You agree to indemnify and hold Wefayo harmless from any claim or demand,
                        including reasonable attorneys' fees, made by any third party due to or arising out of your violation of
                        these terms and conditions or your violation of any rights of another.
                    </p>

                    <p>
                        <h3 className="font-bold">Posting Rules</h3>
                        <ul className="list-decimal pl-5">
                            <li>
                                No “Harassing”. Please do not Post any Content that harass, insult, belittle, threaten, or flame
                                another user.
                            </li>
                            <li>
                                No "Trolling": Please do not Post any Content that disrupts the peace and harmony of this
                                community. Don't create meaningless threads with the sole purpose of starting a dispute.
                            </li>
                            <li>No "Spamming": Please do not double Post or cross-Post the same message in multiple forums. You
                                will not mass-PM or mass-email multiple Wefayo members with the same message. You will not
                                Post to increase views, clicks, or post count. You will not Post outside links or Content without
                                having been involved in multiple discussions or otherwise demonstrating your positive involvement
                                with the community.
                            </li>
                            <li>No "Offensive" Posts, Links, or Images: Please do not Post any Content that is obscene, gory, vulgar,
                                sexually-orientated, hateful, threatening, racist, sexist, discriminatory, or otherwise in violation of
                                any applicable laws. Moderators will ultimately decide if something is appropriate or not.
                            </li>
                            <li>No "Personal" Information: Please do not Post the personal name, address, phone, email, private
                                communications, or other information without the individual’s written permission.
                            </li>
                            <li>No "Out of Bounds" Posts: Our policy is to "contain" some types of posts versus complete
                                censorship. We may dedicate certain topics to specific communities/forums.
                            </li>
                            <li>No "Alias" Accounts: Only one User account is allowed per individual regardless of the reason. If
                                another account is found when the original account is under a ban, then both accounts will be
                                permanently banned, and the person will not be allowed to re-register.
                            </li>
                        </ul>
                    </p>
                    <p>
                        If any of the above rules are violated, We can ban You or other Users without warning, explanation, or
                        appeal, at Our sole discretion.
                    </p>
                    <p>
                        We strongly encourage you to help improve this community by following the above rules and reporting
                        those that do not. Please report any and all Content/Users you find breaking the rules to
                        Support@wefayo.com, and the email subject line must be “Posting Rules Violation”.
                    </p>
                    <p>
                        We do consider that some users may not be aware of the rules and are otherwise good Users. Our
                        moderation team will try to warn Users, but for serious violations and for Users that have been warned
                        before, bans may be implemented that range from one day to permanent.
                    </p>
                    <p>
                        <h3 className="font-bold">Copyrighted Material</h3>
                        Online services sometimes contain material in which Users share they have copied electronically from
                        other sources. Because there are many thousands of possible sources spread out across the Internet
                        (e.g., newspapers, scientific journals, bulletin boards), it is impossible to determine if any Content on the
                        Service infringes the copyright of another party. If you believe that your work has been copied in a way
                        that constitutes copyright infringement, please notify Us so the situation can be promptly addressed.
                        <div className="pl-4 pt-3">

                            Notification Instructions

                            <ul className="list-decimal pl-5 pt-2">
                                <li>The notice must be in email, addressed to Support@Wefayo.com, and the email subject line
                                    must be, “Copyright Infringement Notice”.
                                </li>
                                <li>
                                    It must contain a signature of the person authorized to act on behalf of the copyright owner of
                                    the work allegedly infringed.
                                </li>
                                <li>
                                    The notice must identify the material that is allegedly infringed upon with specific information
                                    about where the infringing material is located on the site. It must also include a link and screen
                                    capture to the material.
                                </li>
                                <li>
                                    The complaining party must provide contact information, including email address, physical
                                    address, and phone number.
                                </li>
                                <li>
                                    The notice should state that the complaint is based on a good faith belief that the use of the
                                    material complained of is not authorized by the copyright owner, its agent or the law.
                                </li>
                                <li>
                                    The notice must be made under penalty of perjury and state that it is accurate and that the
                                    complaining party is authorized to act on behalf of the copyright owner of the exclusive right(s)
                                    allegedly infringed.
                                </li>
                            </ul>
                        </div>
                    </p>
                    <p>
                        Please consult the U.S. Copyright Act itself before sending any such notice:
                        http://www.copyright.gov/title17/92chap5.html#512
                    </p>
                    <p>
                        Wefayo will not be responsible for erroneous or incomplete notices and reserves the right to terminate
                        any account of a User who either makes a false notice or engages in conduct that appears to constitute
                        copyright infringement without limiting the rights or remedies of Wefayo.
                    </p>
                    <p>
                        <h3 className="font-bold">Links</h3>
                        The Service may provide, and Users may include in Content, links to other web sites or resources.
                        Wefayo is not responsible for the availability of such external sites or resources and does not endorse
                        and is not responsible or liable for any content, advertising, products, or other materials on or available
                        from such sites or resources. Wefayo shall not be responsible or liable, directly, or indirectly, for any
                        damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any
                        such content, goods, or services available on such external sites or resources.
                    </p>
                    <p>
                        <h3 className="font-bold">
                            Disclaimer of Warranties</h3>
                        YOU EXPRESSLY AGREE THAT THE USE OF THE SERVICE IS AT YOUR OWN RISK. WEFAYO SHALL NOT BE
                        RESPONSIBLE TO YOU FOR ANY CONTENT FOUND ON THE SERVICE. WEFAYO EXPRESSLY DISCLAIMS ALL
                        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NONINFRINGEMENT. WEFAYO MAKES NO WARRANTY THAT ANY CONTENT ON THE SERVICE WILL MEET
                        YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; NOR DOES WEFAYO
                        MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF SUCH
                        INFORMATION OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH
                        THE SERVICE. YOU UNDERSTAND AND AGREE THAT ANY CONTENT DOWNLOADED OR OTHERWISE
                        OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND
                        THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF
                        DATA THAT RESULTS IN THE DOWNLOAD OF SUCH CONTENT.
                    </p>
                    <p>
                        <h3 className="font-bold">Limitation of Liability</h3>
                        TO THE FULL EXTENT PERMISSIBLE UNDER THE APPLICABLE LAW, WEFAYO SHALL NOT BE LIABLE FOR
                        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES RESULTING FROM THE USE
                        OR INABILITY TO USE THE SERVICE OR FOR COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
                        SERVICES OR RESULTING FROM ANY GOODS OR SERVICES PURCHASED OR OBTAINED OR
                        TRANSACTIONS ENTERED INTO THROUGH THE SERVICE OR RESULTING FROM ALTERATION OR DELETION
                        OF ANY CONTENT, EVEN IF WEFAYO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </p>
                    <p>
                        <h3 className="font-bold">Miscellaneous</h3>
                        These terms and conditions shall be governed by and construed by the laws of the state of Delaware,
                        excluding its conflict of law provisions. You and Wefayo agree to submit to the personal and exclusive
                        jurisdiction of the courts located within the state of Delaware. The failure of Wefayo to exercise or
                        enforce any right or provision of these terms and conditions shall not constitute a waiver of such right or
                        provision. If any provision of these terms and conditions is found by a court of competent jurisdiction to
                        be invalid, the court should endeavor to give effect to the intention reflected in the provision, and the
                        other provisions of these terms and conditions shall remain in full force and effect. You agree that
                        regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to
                        the use of the Service or these terms and conditions must be filed within one (1) year after such claim or
                        cause of action arose. The section titles herein are for convenience only and have no legal or contractual
                        effect. Wefayo reserves the right to change these terms and conditions at any time and from time to
                        time.
                    </p>
                    <p>
                        If you become aware of any Content in the Service that violates these terms and conditions, please
                        email us at support@wefayo.com and the email subject must be “Content Violation”, and include the
                        offending person's identification and cut and paste the relevant content. Wefayo cannot guarantee that
                        any action will be taken due to your email.
                    </p>
                    <p>
                        <h3 className="font-bold">Medical</h3>
                        Before making any medical or related, changes or decisions, consult with your doctor or trained medical
                        professional.
                    </p>
                    <p>
                        <h3 className="font-bold">Policy Changes</h3>
                        These policies may be changed at any time without notice.
                    </p>
                    <p>
                        <h3 className="font-bold">User Consent</h3>
                        If you do not agree with these terms, please do not register/sign up, or use this Service. If you wish to
                        deactivate your account, please email us at support@wefayo.com, and the email subject line must be,
                        “Account Deactivation”.
                    </p>
                </div>
            </article>
        </MainLayout>
    );
};

export default PrivacyPolicy;

