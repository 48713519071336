import { validateAndSend } from "../index";
import { isAuthorised } from "../../utils/auth";

import {
    addSupportTicketEndPoint,
    getSupportTicketsEndPoint,
    getSupportTicketDetailsEndPoint,
    respondToSupportTicketEndPoint
} from "../endPoints";
import { addSupportRequestRules, respondToSupportRequestRules } from "./addSupportRequestRules";


export const addSupportTicket = async (inputs) => {
    return validateAndSend({
        endPoint: addSupportTicketEndPoint,
        inputs: inputs,
        rules: addSupportRequestRules,
        formData: {
            ...inputs
        }
    });
}

export const getSupportTickets = async () => {
    return validateAndSend({
        endPoint: getSupportTicketsEndPoint,
        emit: true
    });
}

export const getSupportTicketDetails = async (id) => {
    return validateAndSend({
        endPoint: getSupportTicketDetailsEndPoint(id),
        emit: true
    });
}

export const respondToSupportTicket = async (inputs) => {
    return validateAndSend({
        endPoint: respondToSupportTicketEndPoint,
        inputs: inputs,
        rules: respondToSupportRequestRules,
        formData: {
            ...inputs
        }
    });
}
