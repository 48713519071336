export default function NavUserAvatar({ user, link = "/profile", imgSizeClass="h-9 w-9"}) {
  const hasAvatar = user && user.avatar && user.avatar.trim().length > 0;

  return (
    <a href={link} className="group block md:flex-shrink-0" title="link to user's profile page">
      <span className="sr-only">link to user's profile page</span>
      <div className="flex items-center">
        <div className={` ${imgSizeClass}`}>
          {hasAvatar &&
            <img
              className={`inline-block rounded-sm ${imgSizeClass}`}
              src={user.avatar}
              alt="user's profile"
              aria-label="link to user's profile page"
            />
          }
          {!hasAvatar &&
            <div className={`flex items-center justify-center rounded-sm uppercase font-bold text-xl font-sans ${imgSizeClass}`}
              style={{ backgroundColor: user.name_initials_bg_color, color: user.name_initials_text_color }}
              aria-label="link to user's profile page"
            >
              {user && user.username ? user.username[0] : ""}
            </div>
          }
        </div>
        {/* <div className="ml-3">
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{user.username}</p>
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
          </div> */}
      </div>
    </a>
  )
}

