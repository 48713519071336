export const rejectCommunityRequestRules = (inputs) => {
    return {
        id: {
            errors: [],
            value: inputs.id,
            isValid: null,
            validations: {
                required: {
                    error: "Community Request Id is required",
                    trim: true
                },
                maxLength: {
                    value: 100,
                    error: "Community Request Id cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Community Request Id"
                // }
            }
        },
        reason: {
            errors: [],
            value: inputs.reason,
            isValid: null,
            validations: {
                required: {
                    error: "Reason is required",
                    trim: true
                },
                maxLength: {
                    value: 300,
                    error: "Reason cannot be more than 300 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Reason cannot be less than 5 characters"
                // }
            }
        }
    }
}