export const changeEmailRules = ({currentEmail, newEmail, password}) => {
    return {
        currentEmail: {
            errors: [],
            value: currentEmail,
            isValid: null,
            validations: {
                required: "Current email address is required",
                maxLength: {
                    value: 60,
                    error: "Current email address cannot be more than 60 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Current email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid current email address format"
            }
        },
        newEmail: {
            errors: [],
            value: newEmail,
            isValid: null,
            validations: {
                required: "New email address is required",
                maxLength: {
                    value: 60,
                    error: "New email address cannot be more than 60 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "New email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid new email address format"
            }
        },
        password: {
            errors: [],
            value: password,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Password is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Password cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Password: at least 6 characters are required",
                // },
            },
        }
    }
}