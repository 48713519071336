export const verifyJoinLinkRules = ({xhs, wiu}) => {
    return {
        xhs: {
            errors: [],
            value: xhs,
            validations: {
                required: "Invalid invite link",
                maxLength: {
                    value: 1500,
                    error: "Invalid invite link: xhs too big",
                },
                minLength: {
                    value: 50,
                    error: "Invalid invite link: xhs too small",
                }
            },
        },
        wiu: {
            errors: [],
            value: wiu,
            isValid: null,
            validations: {
                required: "Invalid invite link: wiu required",
                maxLength: {
                    value: 1500,
                    error: "Invalid invite link: wiu too big",
                },
                minLength: {
                    value: 50,
                    error: "Invalid invite link: wiu too small",
                }
            },
        }
    }
}