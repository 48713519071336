import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Utils } from "../../utils";
import Loader from 'react-loaders';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import { useNavigate } from "react-router-dom";

const MobileMenuSearchModal = ({ isOpen, closeFun }) => {

    const navigate = useNavigate();

    const [open, setOpen] = useState(isOpen);
    const cancelButtonRef = useRef(null);

    const [query, setQuery] = useState("");

    const clearForm = () => {
        setQuery("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate(`/search?term=${query}`);
        clearForm(); 
        closeFun(false)
    };

    const handleClose = async (e) => {
        e.preventDefault();
        clearForm(); 
        closeFun(false)
    };

    

    useEffect(() => {
        clearForm();
    }, []);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isOpen} onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div onClick={handleClose} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full md:items-end md:justify-center p-1 md:text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 "
                        >
                            <Dialog.Panel className="h-[96vh] md:h-auto relative transform overflow-hidden  px-0 pb-0 pt-14 text-left shadow-xl transition-all w-[98vw]  md:w-full md:max-w-3xl md:mx-56">

                                <div className="bg-primary-white mx-0 rounded-md ">

                                    <div className="bg-primary-white flex flex-row justify-between p-3 rounded-t-md items-center ">
                                        <div className="text-gray-900 text-md font-bold">
                                            Search Wefayo
                                        </div>
                                        <div className="flex flex-row items-center">

                                            <div className="ml-4 mr-1 text-gray-900 font-bold cursor-pointer hover:text-purple-200" onClick={() => closeFun(false)}>
                                                X
                                            </div>
                                        </div>

                                    </div>

                                    <div className="max-h-[90%] overflow-x-auto rounded-b-md">

                                        <form className="bg-primary-white drop-shadow-sm" action="#" method="POST" onSubmit={handleSubmit} >
                                            <div className="space-y-12">
                                                <div className="border border-gray-900/10 ">

                                                    <div className="mt-2 py-2 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6  ">
                                                        <div className="col-span-full md:col-span-6">
                                                            <div className="mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="query"
                                                                    id="querymobile"
                                                                    autoComplete="query"
                                                                    value={query}
                                                                    onChange={(e) => setQuery(e.target.value)}
                                                                    className="block app-sm-md-h w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-progress"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-1 py-1 px-4 border-tx mb-2 border-purple-900/10 flex flex-row justify-end ">
                                                        <button role="button" 
                                                            type="submit"
                                                            className="rounded-sm app-sm-md-h app-sm-fs w-full md:w-[150px] flex flex-row items-center justify-center text-center bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                                        >
                                                            {/* <MagnifyingGlassIcon className="h-3 w-3 mr-1" aria-hidden="true" /> */}
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};



export default MobileMenuSearchModal;