import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import LeaderBoard from "../components/leaderboard/LeaderBoard";
import ActivitiesBoard from "../components/leaderboard/ActivitiesBoard";
import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/communities";


const LeaderBoards = () => {

    const [ads, setAds] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [jobListing, setJobListing] = useState(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchCommunities = async () => {
        setIsLoading(true);

        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
            setCommunityBudges(communityBudges);
        } else {
            communityBudges = await getAnonymousCommunityBudges();
            setCommunityBudges(communityBudges);
        }

        const data = await getCommunities();
        const unsortedCommunitiesData = data?.communities ?? [];
        const ads = data?.ads ?? [];
        //the generally
        let sortedData = [];
        unsortedCommunitiesData.forEach((community) => {
            //skip the system community
            if (!community.is_system_community) {
                let forums = community?.forums ?? [];
                forums.sort((a, b) => {
                    const aTitle = a?.title ?? "";
                    const bTitle = b?.title ?? "";
                    return aTitle.localeCompare(bTitle);
                });
                sortedData.push({
                    ...community,
                    forums
                });
            }
        })
        setCommunities(sortedData);
        setAds(ads);
        setIsLoading(false);
    }

    useEffect(() => {

        fetchCommunities();

    }, []);

    const onPinCommunityChanged = async () => {
        fetchCommunities();
    }


    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"leaderBoard"} />
            <div className="mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[18px] md:mt-[10px]  gap-x-2 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <main className="flex-1 flex-grow self-stretch flex ">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col ">

                            {isLoading &&
                                <>
                                    <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                                    <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                                </>
                            }

                            {!isLoading &&
                                <div className="flex flex-col gap-y-1 md:gap-y-2 ">

                                    <LeaderBoard  mode="page" />


                                    <ActivitiesBoard mode="page" />
                                </div>
                            }

                        </div>
                    </div>
                </main>
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default LeaderBoards;

