import React from "react";

const OrderByAscending = ({ fillColor, className, strokeColor }) => {
    const fill = (fillColor) ? fillColor : "currentColor";
    const stroke = (strokeColor) ? strokeColor : "currentColor";
    let classes = `w-6 h-6`;
    if (className && className.indexOf('w-') >= 0) {
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
        </svg>

    );
}

export default OrderByAscending;




