export const addJobRules = (inputs) => { 
    return {
        jobTitle: {
            errors: [],
            value: inputs.jobTitle,
            isValid: null,
            validations: {
                required: "Job Title is required",
                maxLength: {
                    value: 300,
                    error: "Job Title cannot be more than 300 characters"
                }
            }
        },
        jobId: {
            errors: [],
            value: inputs.jobId,
            isValid: null,
            validations: {
                required: "Job Id is required",
                maxLength: {
                    value: 100,
                    error: "Job Id cannot be more than 100 characters"
                }
            }
        },
        compesation: {
            errors: [],
            value: inputs.compesation,
            isValid: null,
            validations: {
                required: "Compensation is required",
                maxLength: {
                    value: 300,
                    error: "Compensation: cannot be more than 300 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Compensation: at least 5 characters are required"
                // }
            }
        },
        jobType: {
            errors: [],
            value: inputs.jobType,
            isValid: null,
            validations: {
                required: "Job Type is required",
                maxLength: {
                    value: 20,
                    error: "Job Type cannot be more than 20 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Job Type: at least 5 characters are required"
                // }
            }
        },
        locationType: {
            errors: [],
            value: inputs.locationType,
            isValid: null,
            validations: {
                required: "Location Type is required",
                maxLength: {
                    value: 20,
                    error: "Location Type: cannot be more than 20 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Location Type: at least 5 characters are required"
                // }
            }
        },
        state: {
            errors: [],
            value: inputs.state,
            isValid: null,
            validations: {
                required: "State is required",
                maxLength: {
                    value: 100,
                    error: "State: cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "State: at least 5 characters are required"
                // }
            }
        },
        city: {
            errors: [],
            value: inputs.city,
            isValid: null,
            validations: {
                required: "City is required",
                maxLength: {
                    value: 100,
                    error: "City: cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "City: at least 5 characters are required"
                // }
            }
        },
        zipcode: {
            errors: [],
            value: inputs.zipcode,
            isValid: null,
            validations: {
                required: "Zipcode is required",
                maxLength: {
                    value: 20,
                    error: "Zipcode: cannot be more than 20 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Zipcode: at least 5 characters are required"
                // }
            }
        },
        industry: {
            errors: [],
            value: inputs.industry,
            isValid: null,
            validations: {
                required: "Industry is required",
                maxLength: {
                    value: 100,
                    error: "Industry: cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Industry: at least 5 characters are required"
                // }
            }
        },
        status: {
            errors: [],
            value: inputs.status,
            isValid: null,
            validations: {
                required: "Status is required",
                maxLength: {
                    value: 100,
                    error: "Status: cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Status: at least 5 characters are required"
                // }
            }
        },
        callToActionLink: {
            errors: [],
            value: inputs.callToActionLink,
            isValid: null,
            validations: {
                required: "Call to Action is required",
                maxLength: {
                    value: 500,
                    error: "Call to Action: cannot be more than 500 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Call to Action: at least 5 characters are required"
                // }
            }
        },
        skills: {
            errors: [],
            value: inputs.skills,
            isValid: null,
            validations: {
                required: "Skills are required",
                maxLength: {
                    value: 5000,
                    error: "Skills: cannot be more than 5,000 characters"
                }
            }
        },
        qualifications: {
            errors: [],
            value: inputs.qualifications,
            isValid: null,
            validations: {
                maxLength: {
                    value: 5000,
                    error: "Qualifications: cannot be more than 5,000 characters"
                }
            }
        },
        specialAccomodations: {
            errors: [],
            value: inputs.specialAccomodations,
            isValid: null,
            validations: {
                maxLength: {
                    value: 5000,
                    error: "Special Accommodations: cannot be more than 5,000 characters"
                }
            }
        },
        details: {
            errors: [],
            value: inputs.details,
            isValid: null,
            validations: {
                required: "Job Details are required",
                // maxWords: {
                //     value: 10000,
                //     error: "Details: cannot be more than 10,000 words"
                // }
            }
        },
    }
}