import { accountActivationRules } from "./accountActivationRules"

export const resendAccountActivationCodeRules = ({email}) => {
    let code = "";
    const rules = accountActivationRules({email, code});
    return {
        email: {
            ... rules.email
        },
    }
}