import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Avatar from "../components/icons/Avatar";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import AcceptRequestModal from "../components/request/AcceptRequestModal";
import RejectRequestModal from "../components/request/RejectRequestModal";
import MainLayout from "../components/layouts/MainLayout";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { addCommunityRequest, getCommunityRequests, searchCommunityRequests } from "../core/communities";
import ReactTimeAgo from 'react-time-ago';

const CommunityRequestForm = () => {

  const requests = labData.communityRequests;

  const [isAcceptRequestModalOpen, setIsAcceptRequestModalOpen] = useState(false);
  const toggleAcceptRequestModal = () => {
    setIsAcceptRequestModalOpen(!isAcceptRequestModalOpen);
  };

  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
  const toggleRejectRequestModal = () => {
    setIsRejectRequestModalOpen(!isRejectRequestModalOpen);
  };

  let loggedInUserStr = sessionStorage.getItem("user");
  let loggedInUser = null;
  if (loggedInUserStr) {
    loggedInUser = JSON.parse(loggedInUserStr);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [communityRequests, setCommunityRequests] = useState([]);
  const [user, setUser] = useState(loggedInUser);

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchErrors, setSearchErrors] = useState([]);

  const [targetRequest, setTargetRequest] = useState(null);

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setSearchTerm("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setIsLoading(true);

    const inputs = {
      title,
      description
    };
    let response = null;
    //normal post
    response = await addCommunityRequest(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }
    clearForm();
    toast.success('Your Request Was Posted Successfully', {
      icon: '✅',
      position: "top-center"
    });
    fetchCommunityRequests();
  }

  const fetchCommunityRequests = async () => {
    const requests = await getCommunityRequests();
    setCommunityRequests(requests);
  }

  useEffect(() => {
    fetchCommunityRequests();
  }, []);


  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitSearchForm();
    }
  };
  const submitSearchForm = async () => {
    setSearchErrors([]);
    setIsSearchLoading(true);
    const inputs = {
      searchTerm
    };
    let response = await searchCommunityRequests(inputs);
    setIsSearchLoading(false);
    if (!Array.isArray(response) || (Array.isArray(response) && response.length > 0 && typeof response[0] === 'string')) {
      return setSearchErrors(response);
    }
    let searchedRequests = response ? response : [];
    const limit = -1; //todo: a pagination
    if (limit !== -1) {
      searchedRequests = searchedRequests.slice(0, limit - 1);
    }
    setCommunityRequests(searchedRequests);
  }
  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    submitSearchForm();
  }

  const handleOpenRejectForm = (e, request) => {
    e.preventDefault();
    setTargetRequest(request);
    toggleRejectRequestModal();
  };

  const handleOpenToAcceptForm = (e, request) => {
    e.preventDefault();
    setTargetRequest(request);
    toggleAcceptRequestModal();
  };

  const handleOnSucess = () => {
    fetchCommunityRequests();
  }

  const handleShowThis = () => {
    toast.success('Your Request Was Posted Successfully', {
      icon: '✅',
      position: "top-center"
    });
  }


  return (
    <MainLayout currentPage={"commuity-request"} >
      <div className="flex-grow flex flex-col gap-y-2 ">
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            className: 'app-toastx',
            position: 'top-center'
            // style: {
            //   border: '1px solid #713200',
            //   padding: '16px',
            //   color: '#713200',
            // },
          }}
        />
        <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white drop-shadow-md rounded-md">
          <div className="space-y-12">
            <div className="border border-gray-900/10 ">
              <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">Community Request Form</h2>
              <p className=" text-sm text-gray-600 px-4 pt-2 pt-0" onClick={handleShowThis}>
                Fill out this form and submit it to request the administrators to add a community.
              </p>

              <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">
                {/* title */}
                <div className="col-span-full">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Community Name
                    <span className="text-red-900 mx-1">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      required
                      value={title}
                      disabled={isLoading}
                      onChange={(e) => setTitle(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                    />
                  </div>
                </div>
                {/* description */}
                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                    <span className="text-red-900 mx-1">*</span>
                    <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                      - Provide a brief explanation about the community you want to create.
                    </span>

                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      rows={2}
                      required
                      value={description}
                      disabled={isLoading}
                      onChange={(e) => setDescription(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-end ">
                <button role="button"
                  type="submit"
                  disabled={isLoading}
                  className="w-full md:w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                >
                  {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                  Submit
                </button>
              </div>

              {errors.length > 0 &&
                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                  {errors[0]}
                </div>
              }
            </div>
          </div>
        </form>

        <div className="bg-white rounded-md">
          <div className="bg-primary-white rounded-md border-b border-gray-200 flex flex-col md:flex-row justify-between p-3  md:items-center w-full ">
            <div className="text-gray-900 text-sm font-bold ">
              {user.role !== "admin" ? "Your " : " "}Community Requests
            </div>
            {user.role === "admin" &&
              <div className="flex flex-col pt-3 md:pt-0 mt-2 md:mt-0 md:w-1/2 border-t border-gray-200 md:border-0 ">
                <div className="flex flex-row  ">
                  <label htmlFor="search" className="sr-only">
                    Search ...
                  </label>
                  <div className="relative text-gray-400 focus-within:text-gray-600 w-full" >
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                      <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                    </div>
                    <input
                      id="searchjob"
                      className="block w-full rounded-full border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6 disabled:cursor-progress"
                      placeholder="search requests ..."
                      type="search"
                      name="searchjob"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      value={searchTerm}
                      disabled={isSearchLoading || isLoading}
                    />
                    <button role="button"
                      type="button"
                      disabled={isSearchLoading || isLoading}
                      onClick={handleSubmitSearch}
                      className="cursor-pointer flex justify-center items-center text-white absolute right-0 border border-purple-900 top-[0px] py-1 bg-purple-800 hover:bg-purple-700 font-sm rounded-r-full text-sm px-4 ">

                      search
                    </button>
                  </div>
                </div>

                <div className="relative w-full">

                  {searchErrors.length > 0 &&
                    <div className="app-error text-red-400 font-bold text-xs px-2">
                      {searchErrors[0]}
                    </div>
                  }
                  <div className="absolute  -top-6 right-20">
                    {isSearchLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)', color: 'blue' }} />}
                  </div>
                </div>
              </div>
            }
          </div>
          <ul role="list" className="divide-y divide-gray-100  mb-20 md:mb-0">
            {communityRequests.map((request) => (
              <li key={request._id} className="flex even:bg-white odd:bg-slate-50 last:rounded-b-md">
                <div className="flex flex-row w-full p-3">
                  <div className="min-w-[3.5rem]">
                    <Avatar user={request.user} />
                  </div>
                  <div className="flex-auto flex-grow ml-3">
                    <div className="flex flex-col items-baseline w-full gap-x-4">
                      <p className="text-lg flex flex-col md:flex-row w-full md:items-center justify-between font-semibold leading-6 text-gray-900 mb-1 ">
                        <span className="text-gray-400 text-xs ">{request.user.username}</span>

                        {request.status === "pending" &&
                          <span className="text-xs font-bold  text-orange-700">Waiting Admin Response</span>
                        }
                        {request.status === "rejected" &&
                          <span className="text-xs font-bold  text-red-700">Rejected</span>
                        }
                        {request.status === "accepted" &&
                          <span className="text-xs font-bold  text-green-700">Accepted</span>
                        }
                      </p>
                      <div className="flex flex-col md:flex-row md:items-center w-full justify-between">
                        <p className="text-sm font-semibold leading-6  text-gray-900">{request.title}</p>
                        <p className="flex-none text-xs text-gray-600">
                          <ReactTimeAgo date={request.createdAt} locale="en-US" />
                        </p>
                      </div>
                    </div>
                    <p className="w-full  md:line-clamp-3 text-xs leading-5 text-gray-600 ">{request.description}
                      Lorem ipsum dolor sit amet consectetur adipiscing elit praesent dictumst mauris phasellus, commodo duis arcu neque rutrum class eros pellentesque vivamus. Pretium class natoque netus sociis hendrerit donec ornare, pulvinar auctor a euismod cubilia dignissim, porttitor eros enim himenaeos habitasse sollicitudin. Fusce laoreet vitae sagittis lectus mollis nostra hendrerit, platea sociosqu semper arcu himenaeos conubia, condimentum vehicula tristique sed est pharetra.
                    </p>
                    <div className="py-1 flex flex-row items-center justify-between">
                      {request.status === "accepted" &&
                        <>
                          <div className="flex flex-col justify-start">
                            <a
                              href={request.created_community_url}
                              className=" py-2 text-sm font-semibold text-purple-900 hover:bg-text-800 ml-5 "
                            >
                              {request.created_community_url}
                            </a>
                            <span className="text-xs font-bold rounded-sm p-1 bg-blue-100 text-blue-800 ml-5 p-2">
                              {request.reason}
                            </span>
                          </div>
                        </>
                      }

                      {(request.status === "pending" && user?.role === "admin") &&
                        <>
                          <div>&nbsp;</div>
                          <div className="flex items-center">
                            <button role="button"
                              className="mx-1 flex flex-row items-center rounded-sm bg-red-700 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-800 "
                              onClick={(e) => handleOpenRejectForm(e, request)}
                            >
                              Reject
                            </button>

                            <button role="button"
                              className="rounded-sm bg-purple-900 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 "
                              onClick={(e) => handleOpenToAcceptForm(e, request)}
                            >
                              Accept
                            </button>
                          </div>
                        </>
                      }

                      {request.status === "rejected" &&
                        <span className="text-xs font-bold rounded-sm p-1 bg-red-200 text-red-800 ml-5 p-2">
                          {request.reason}
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <AcceptRequestModal isOpen={isAcceptRequestModalOpen} closeFun={setIsAcceptRequestModalOpen} request={targetRequest} onSuccess={handleOnSucess} />
        <RejectRequestModal isOpen={isRejectRequestModalOpen} closeFun={setIsRejectRequestModalOpen} request={targetRequest} onSuccess={handleOnSucess} />
      </div>
    </MainLayout>
  );
};

export default CommunityRequestForm;
