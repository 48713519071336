export const addPostRules = (inputs) => {
    return {
        title: {
            errors: [],
            value: inputs.title,
            isValid: null,
            validations: {
                required: "Post title is required",
                maxLength: {
                    value: 300,
                    error: "Post title cannot be more than 300 characters"
                },
                // minLength: {
                //     value: 10,
                //     error: "Post title: at least 10 characters are required"
                // }
            }
        },
        subTitle: {
            errors: [],
            value: inputs.subTitle,
            isValid: null,
            validations: {
                maxLength: {
                    value: 100,
                    error: "Sub title cannot be more than 100 characters"
                }
            }
        },
        details: {
            errors: [],
            value: inputs.details,
            isValid: null,
            validations: {
                // maxLength: {
                //     value: 10000,
                //     error: "Details: cannot be more than 10,000 characters"
                // }
            }
        },
    }
}