import { validateAndSend } from "../index";
import { searchCommunityRules } from "../communities/searchCommunitiesRules";
import { isAuthorised } from "../../utils/auth";

import {
    getUsersChatsListEndPoint,
    searchUsersChatsListEndPoint,
    sendChatMessageEndPoint
} from "../endPoints";
import { sendMessageRules } from "./sendMessageRules";

export const getUsersChatsList = async (withUserId) => {
    return validateAndSend({
        endPoint: getUsersChatsListEndPoint(withUserId),
        emit: true
    });
}

export const searchUsersChatsList = async (inputs) => {
    return validateAndSend({
        endPoint: searchUsersChatsListEndPoint,
        inputs: inputs,
        rules: searchCommunityRules,
        formData: {
            ...inputs
        },
        emit: true
    });
}

export const sendUsersChatMessage = async (inputs) => {
    return validateAndSend({
        endPoint: sendChatMessageEndPoint,
        inputs: inputs,
        rules: sendMessageRules,
        formData: {
            ...inputs
        },
        emit: true
    });
}

