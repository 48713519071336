import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Utils } from "../../utils";
import CopyClipIcon from "../icons/CopyClip";
import Loader from 'react-loaders';
import { invite, generateSocialInviteLink } from "../../core/invite";
import toast, { Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const InviteUserModal = ({ isOpen, closeFun }) => {
    const [open, setOpen] = useState(isOpen);
    const cancelButtonRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [inviteLink, setInviteLink] = useState("");
    const [errors, setErrors] = useState([]);

    const [wrapingLink, setWrapingLink] = useState("");

    const clearForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        const inputs = {
            email: email,
            name: name,
            message: message
        };
        let response = await invite(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        setOpen(false);
        closeFun(false);
        toast.success('Your invitation has been sent', {
            icon: '✅'
        });
    };

    

    useEffect(() => {
        let user = JSON.parse(sessionStorage.getItem("user"));
        if (user && (typeof user.generated_public_invite_link == 'undefined' || user.generated_public_invite_link.length == 0)) {
            //generate invite link
            const asyncWrapper = async () => {
                let response = await generateSocialInviteLink();
                if (response === true) {
                    user = JSON.parse(sessionStorage.getItem("user"));
                    setInviteLink(user.generated_public_invite_link);
                    let modifiedDisplayLink = Utils.insertBreaks(user.generated_public_invite_link, 3);
                    setWrapingLink(modifiedDisplayLink);
                }
            };
            asyncWrapper();
        } else if (user && user.generated_public_invite_link && user.generated_public_invite_link.trim().length > 0) {
            setInviteLink(user.generated_public_invite_link);
            let modifiedDisplayLink = Utils.insertBreaks(user.generated_public_invite_link, 3);
            setWrapingLink(modifiedDisplayLink);
        }
    }, []);

    const handleOnCopiedToClipBoard = (e) => {
        toast.success('Link copied to clipboard', {
            icon: '✅'
        });
        setOpen(false);
        closeFun(false);
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isOpen} onClose={closeFun}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    containerClassName="thisnmae"
                />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="h-[96vh] md:h-auto relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                <div className="bg-primary-white mx-0 md:mx-4 rounded-md">

                                    <div className="bg-primary-white flex flex-row justify-between p-3 rounded-t-md items-center ">
                                        <div className="text-gray-900 text-md font-bold">
                                            Invite A Member
                                        </div>
                                        <div className="flex flex-row items-center">

                                            <div className="ml-4 mr-1 text-gray-900 font-bold cursor-pointer hover:text-purple-200" onClick={() => closeFun(false)}>
                                                X
                                            </div>
                                        </div>

                                    </div>

                                    <div className="max-h-[90%] overflow-x-auto rounded-b-md">

                                        <form className="bg-primary-white drop-shadow-sm" action="#" method="POST" onSubmit={handleSubmit} >
                                            <div className="space-y-12">
                                                <div className="border border-gray-900/10 ">

                                                    <div className="mt-2 py-2 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6  ">
                                                        {/* name */}
                                                        <div className="col-span-full md:col-span-6">
                                                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                                <b>Name</b>
                                                                <span className="text-red-900 mx-1">*</span>
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    id="name"
                                                                    autoComplete="name"
                                                                    value={name}
                                                                    disabled={isLoading}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-progress"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* sub title */}
                                                        <div className="col-span-full md:col-span-6">
                                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                                <b>Email Address</b>
                                                                <span className="text-red-900 mx-1">*</span>
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    id="email"
                                                                    autoComplete="email"
                                                                    value={email}
                                                                    disabled={isLoading}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-progress"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* description */}
                                                        <div className="col-span-full">
                                                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                                <b>Message</b>
                                                                <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                                                    - Let them know why you are inviting them
                                                                </span>
                                                            </label>
                                                            <div className="mt-2">
                                                                <textarea
                                                                    id="message"
                                                                    name="message"
                                                                    type="message"
                                                                    
                                                                    value={message}
                                                                    disabled={isLoading}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-progress"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-2 py-1 px-4 border-tx mb-2 border-purple-900/10 flex flex-row justify-end ">
                                                        <button role="button" 
                                                            type="submit"
                                                            disabled={isLoading}
                                                            className="rounded-sm w-full md:w-[150px] flex flex-row justify-center text-center bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                                        >
                                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                            Submit
                                                        </button>
                                                    </div>
                                                    {errors.length > 0 &&
                                                        <div className="app-error text-red-400 font-bold text-xs py-1 px-5">
                                                            {errors[0]}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>

                                        <div className="flex flex-row items-center w-full border-b border-gray-100 drop-shadow-sm py-4 px-4 bg-gray-100">

                                            <div className="mr-4">
                                                <CopyToClipboard text={inviteLink}
                                                    onCopy={handleOnCopiedToClipBoard}>
                                                    <button role="button"  type="button" disabled={isLoading} className="cursor-pointer inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:bg-purple-100 active:text-gray-300 ">
                                                        <CopyClipIcon aria-hidden="true" />
                                                    </button>
                                                </CopyToClipboard>
                                            </div>

                                            <div className="flex flex-col flex-wrap text-wrap">
                                                <div className="text-sm text-wrap">
                                                    Or Copy and paste the link on social media, in an email, or in a text message.
                                                </div>
                                                <CopyToClipboard text={inviteLink}
                                                    onCopy={handleOnCopiedToClipBoard}>
                                                        <div className="text-xs text-purple-900 xwhitespace-nowrap" dangerouslySetInnerHTML={{ __html: wrapingLink }} ></div>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};



export default InviteUserModal;