export const resetPasswordRules = ({email, password, password2, code}) => {
    return {
        email: {
            errors: [],
            value: email,
            isValid: null,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address cannot be more than 60 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid email address"
            }
        },
        password: {
            errors: [],
            value: password,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Password is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Password cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Password: at least 6 characters are required",
                // },
            },
        },
        comfirm_password: {
            errors: [],
            value: password2,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Confirmation password is required"
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Confirmation password cannot be more than 15 characters"
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Confirmation password: at least 6 characters are required"
                // },
                sameAs: {
                    value: 'password',
                    error: "Confirmation password: the two passwords are not the same"
                }
            }
        },
        code: {
            errors: [],
            value: code,
            isValid: null,
            validations: {
              required: "Reset Code is required",
              length: {
                value: 6,
                error: "Reset Code: Expected a 6 digit code",
              },
              digitsOnly: "REset Code: Only numbers are allowed",
            }
        },
    }
}