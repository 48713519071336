import React from "react";

const ArrowLongLeft = ({fillColor, className, strokeColor}) => {
    const fill = (fillColor)?fillColor:  "currentColor";
    // const stroke = (strokeColor)?strokeColor:  "currentColor";
    let classes = `w-6 h-6`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fill} className={classes}>
            <path fillRule="evenodd" d="M7.28 7.72a.75.75 0 010 1.06l-2.47 2.47H21a.75.75 0 010 1.5H4.81l2.47 2.47a.75.75 0 11-1.06 1.06l-3.75-3.75a.75.75 0 010-1.06l3.75-3.75a.75.75 0 011.06 0z" clipRule="evenodd" />
        </svg>
    );
}

export default ArrowLongLeft;




