import React, { Fragment, useEffect, useRef, useState } from "react";
import MagnifyingGlass from "../icons/MagnifyingGlass";

const JobsSkeleton = ({ items = 5 }) => {
    return (
        <div role="status" className="flex flex-col mb-2 w-full animate-pulse bg-gray-100 mb-5">
            <div className="flex flex-row w-full justify-center p-2 gap-x-2 border-b border-gray-200 ">
                <div className="h-3 w-24 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            <div
                className="bg-gray-200 flex flex-row flex-wrap justify-between  text-white  px-3 py-2 cursor-pointer">
                <div className="flex flex-col justify-between items-center flex-grow">
                    <div className="flex flex-row  w-full ">
                        <label htmlFor="search" className="sr-only">Search Posted Jobs</label>
                        <div className="relative text-gray-400 focus-within:text-gray-600 w-full" >
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                <MagnifyingGlass className="h-4 w-4 text-gray-900" aria-hidden="true" />
                            </div>
                            <input
                                id="searchjob"
                                className="block w-full rounded-full border-1 border-gray-300  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-300 focus:ring-0 focus:ring-purple-300  sm:text-sm sm:leading-6 disabled:cursor-progress"
                                placeholder="search posted jobs ..."
                                type="search"
                                name="searchjob"
                                disabled
                            />
                            <button role="button" 
                                type="submit"
                                disabled
                                className="cursor-pointer flex justify-center items-center text-white absolute right-0 border border-gray-400 top-[0px] py-1 bg-gray-400 hover:bg-gray-700 font-sm rounded-r-full text-sm px-4 ">

                                search
                            </button>
                        </div>
                    </div>
                </div>
                <div className="cursor-pointer accent-text-color"></div>
            </div>
            {[...Array(items)].map((e, i) => (
                <div key={i} className="flex flex-row w-full p-4 gap-x-2 border-b border-gray-200">
                    <div className="h-16 w-16 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="flex flex-row flex-1 justify-between ">
                        <div className="flex flex-col justify-between pt-2">
                            <div className="h-2 w-24 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="h-3 w-48 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="h-3 w-20 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="flex flex-row gap-x-4">
                                <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                                <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                                <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between items-end pt-3 ">
                            <div className="h-3 w-10 bg-gray-200 rounded-sm dark:bg-gray-700  ">
                            </div>
                            <div className="flex flex-row gap-x-4 pt-1 justify-end">
                                <div className="h-5 w-20 bg-gray-200 rounded-full dark:bg-gray-700 ">
                                </div>
                                <div className="h-5 w-20 bg-gray-200 rounded-full dark:bg-gray-700 ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default JobsSkeleton;

