import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { deleteForum, getForum, updateForum } from "../core/communities";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import ForumHeaderSection from "../components/forum/ForumHeaderSection";
import MainLayout from "../components/layouts/MainLayout";

const EditForum = () => {
    const { comid, id } = useParams();
    const navigate = useNavigate();

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [forum, setForum] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [order, setOrder] = useState("10");
    const [status, setStatus] = useState("active");
    const [icon, setIcon] = useState("");
    const [breadcrumbPages, setBreadcrumbPages] = useState([]);

    const clearForm = () => {
        setTitle("");
        setDescription("");
        setOrder("10");
        setStatus("active");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: forum._id,
            title,
            description,
            order,
            status,
            icon
        };
        let response = null;
        //normal post
        response = await updateForum(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The Forum was updated successfully', {
            icon: '✅'
        });
        fetchData();
    }

    const fetchData = async () => {
        const results = await getForum(id);
        setForum(results);
        setTitle(results.title);
        setOrder(results.display_order);
        setStatus(results.status);
        setDescription(results.description);
        setIcon(results.icon.trim());

        setBreadcrumbPages([
            { name: results?.community.title, href: `/`, current: false },
            { name: results?.title, href: `/community/${comid}/forum/${id}`, current: false },
            { name: "Edit Forum", href: ``, current: true },
        ]);
    }

    useEffect(() => {
        fetchData();
    }, []);


    const cancelButtonRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleDeleteModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeFun = () => {
        setIsModalOpen(false);
    }
    const goToLogin = () => {
        navigate(`/login`);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: forum._id
        };
        let response = null;
        //normal post
        response = await deleteForum(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The Forum was deleted successfully', {
            icon: '✅'
        });
        navigate(`/`);
    }

    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const handleOnFileChange = async (changeEvent) => {
        console.log("CLOUDINARY_URL", CLOUDINARY_URL);
        changeEvent.preventDefault();
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIcon(onLoadEvent.target.result);

            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setIcon(data.secure_url);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };


    return (
        <MainLayout currentPage={"edit-community"} >
            <div className=" flex-grow  md:max-h-[calc(100vh-70px)] flex flex-col flex-1 ">
                <ForumHeaderSection
                    forum={forum}
                    breadcrumbPages={breadcrumbPages}
                />
                <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-b-md flex flex-1 ">
                    <div className="space-y-12 flex-1 flex">
                        <div className="flex-1 flex flex-col border border-gray-900/10 rounded-b-md ">
                            <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0 flex justify-between">
                                Edit Forum

                                <button role="button"
                                    type="button"
                                    disabled={isLoading}
                                    className="rounded-sm bg-red-600 px-3 py-1 text-sm font-semibold text-white  hover:bg-red-500 disabled:cursor-not-allowed"
                                    onClick={loggedInUser !== null ? toggleDeleteModal : goToLogin}
                                >
                                    Delete
                                </button>
                            </h2>
                            <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                Fill out this form and submit it to update the forum details.
                            </p>

                            <div className="flex-1 flex flex-col justify-start items-start mt-2 py-5 px-4 gap-x-6 gap-y-4 border-t border-purple-900/10 bg-white">
                                {/* icon */}
                                <div className="pt-5 flex items-center w-full gap-x-3 ">

                                    <div className="pt-x flex flex-col ">
                                        <img
                                            className="inline-block h-16 w-16 rounded-sm"
                                            src={icon}
                                            alt=""
                                        />
                                    </div>

                                    <div className="pt-2 flex flex-col flex-grow self-stretch justify-end items-start">
                                        <label htmlFor="photo" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                            Icon
                                        </label>

                                        <div className="flex items-center justify-center bg-grey-lighter mt-2">
                                            <label disabled={isLoading} htmlFor="select-icon" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                </svg>
                                                <span className="text-xs font-medium leading-6 text-gray-900 ">
                                                    {(!icon || icon == "") ? 'Select' : 'Change'} icon photo
                                                </span>
                                                <input id="select-icon" disabled={isLoading} name="select-icon" type='file' className="hidden" onChange={handleOnFileChange} />
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                {/* title */}
                                <div className="w-full">
                                    <label htmlFor="com-title" className="block text-sm font-medium leading-6 text-gray-900">
                                        Forum Title
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="title"
                                            id="com-title"
                                            required
                                            value={title}
                                            disabled={isLoading}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex flex-row items-center gap-x-4">
                                    {/* order */}
                                    <div className="w-1/2">
                                        <label htmlFor="display-order" className="block text-sm font-medium leading-6 text-gray-900">

                                            Display Order
                                            <span className="text-red-900 mx-1">*</span>

                                        </label>
                                        <div className="">
                                            <input
                                                type="number"
                                                name="order"
                                                id="order"
                                                value={order}
                                                disabled={isLoading}
                                                onChange={(e) => setOrder(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-0"
                                            />
                                        </div>
                                    </div>
                                    {/* status */}
                                    <div className="w-1/2">
                                        <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">

                                            Status
                                            <span className="text-red-900 mx-1">*</span>

                                        </label>
                                        <div className="">
                                            <select
                                                id="status"
                                                name="status"
                                                className=" block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                required
                                                value={status}
                                                disabled={isLoading}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value="active">Active</option>
                                                <option value="disabled">disabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* description */}
                                <div className="w-full">
                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                        Brief Description (optional)
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={2}
                                            value={description}
                                            disabled={isLoading}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-between ">
                                <a
                                    href={`/community/${comid}/forum/${id}`}
                                    className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                >
                                    Cancel
                                </a>

                                <button role="button"
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                >
                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    Submit
                                </button>
                            </div>

                            {errors.length > 0 &&
                                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                    {errors[0]}
                                </div>
                            }
                        </div>
                    </div>
                </form>
                <Transition.Root show={isModalOpen} as={Fragment}>
                    <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isModalOpen} onClose={closeFun}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56 ">

                                        <div className="bg-white mx-4 rounded-md">

                                            <div className="bg-primary-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-t-md items-center ">
                                                <div className="text-gray-900 text-md font-bold">
                                                    Delete This Forum
                                                </div>
                                            </div>

                                            <div className="overflow-x-auto rounded-b-sm p-5 text-lg">
                                                Deleting the forum deletes all its posts and associated comments. Do you want to continue with this action ?
                                            </div>

                                            <div className="bg-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-b-md items-center ">
                                                <button role="button"
                                                    onClick={(e) => setIsModalOpen(false)}
                                                    className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                                >
                                                    Cancel
                                                </button>

                                                <button role="button"
                                                    type="button"
                                                    disabled={isLoading}
                                                    onClick={handleDelete}
                                                    className="w-[200px] flex justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed"
                                                >
                                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                    Delete
                                                </button>
                                            </div>
                                        </div>

                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </MainLayout>
    );
};

export default EditForum;
