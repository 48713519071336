import React, { useRef, useEffect, useState } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from "react-router-dom";
import { addPost, getForum } from "../core/communities";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import ForumHeaderSection from "../components/forum/ForumHeaderSection";
import MainLayout from "../components/layouts/MainLayout";


const ForumPostForm = () => {
    const { comid, id } = useParams();
    const navigate = useNavigate();

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const editorRef = useRef(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [editorKey, setEditorKey] = useState(4);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [forum, setForum] = useState(null);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [details, setDetails] = useState(`<p></p>`);
    const [breadcrumbPages, setBreadcrumbPages] = useState([]);

    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const [isFileUpLoading, setIsFileUpLoading] = useState(false);
    const [media, setMedia] = useState("");

    useEffect(() => {
        const fetchForum = async () => {
            const data = await getForum(id);
            setForum(data);

            setBreadcrumbPages([
                { name: data?.community.title, href: `/`, current: false },
                { name: data?.title, href: `/community/${data?.community._id}/forum/${id}`, current: false },
                { name: "Add Post", href: ``, current: true },
            ]);
        }
        fetchForum();
    }, []);

    const clearForm = () => {
        setTitle("");
        setSubTitle("");
        setDetails("<p></p>");
        setEditorKey(editorKey * 2);
        setMedia("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const wordCount = editorRef.current.plugins.wordcount.getCount();
        if (wordCount === 0) {
            setIsLoading(false);
            setErrors(["Please add post description"]);
            return false;
        }

        const inputs = {
            forumId: forum._id,
            title,
            subTitle,
            details,
            media
        };
        let response = null;
        response = await addPost(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('Post was submitted successfully', {
            icon: '✅'
        });

        navigate(`/community/${comid}/forum/${forum._id}`);
    }



    const handleOnFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setMedia(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-image');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setMedia(data.secure_url);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    const [maximumLength, setMaximumLength] = useState(140000);
    const handleEditorUpdate = (text, editor) => {
        const length = editor.getContent({ format: 'text' }).length;
        if (length <= maximumLength) {
            setDetails(text);
        }else{
            toast.error('Maximum content length exceeded. Accepts upto 20,000 words', {
                icon: '🚫',
                duration: 1500
            });
        }
    };

    return (
        <MainLayout currentPage={"addpost"} >

            <div className="flex-grow flex flex-col  ">
                <ForumHeaderSection
                    forum={forum}
                    breadcrumbPages={breadcrumbPages}
                />
                {/* <ForumHeroSection forum={forum} /> */}
                <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-b-md drop-shadow-sm mb-20 md:mb-0">
                    <div className="space-y-12">
                        <div className="border border-gray-900/10 rounded-b-md ">
                            <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">New Post</h2>
                            <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                Fill out and submit this form to add a new post to the forum.
                            </p>

                            <div className="bg-white mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-2 grid-cols-6 border-t border-purple-900/10 ">
                                {/* title */}
                                <div className="col-span-full">
                                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Title</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            autoComplete="title"
                                            value={title}
                                            disabled={isLoading}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>
                                {/* sub title */}
                                {/* <div className="col-span-full">
                                            <label htmlFor="sub-title" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Sub Title</b>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="sub-title"
                                                    id="sub-title"
                                                    value={subTitle}
                                                    disabled={isLoading}
                                                    onChange={(e) => setSubTitle(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div> */}
                                {/* media */}
                                <div className="col-span-full mb-4">

                                    <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Image</b>
                                    </label>

                                    <div className="flex items-start justify-start bg-grey-lighter mt-2">
                                        <label disabled={isLoading} htmlFor="select-image" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                            {!isFileUpLoading &&
                                                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                </svg>
                                            }
                                            <span className={`text-xs font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                {!isFileUpLoading &&
                                                    <span>
                                                        {(!media || media.length === 0) ? 'Upload' : 'Upload'} Picture
                                                    </span>
                                                }
                                                {isFileUpLoading && "uploading"}
                                            </span>
                                            <input id="select-image" disabled={isLoading} name="select-image" type='file' className="hidden" onChange={handleOnFileChange} />
                                        </label>
                                    </div>

                                    {media.length > 0 &&
                                        <div className="flex flex-col mt-2 m" >
                                            <img
                                                className="inline-block max-h-[350px] rounded-sm"
                                                src={media}
                                                alt=""
                                            />
                                        </div>
                                    }
                                </div>

                                {/* description */}
                                <div className="col-span-full">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Description</b>
                                        <span className="text-red-900 mx-1">*</span>
                                        {/* <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                                    - Provide details of your post
                                                </span> */}
                                    </label>
                                    <div className="mt-2">
                                        <Editor
                                            key={'editor1-' + editorKey}
                                            apiKey={TINY_MCE_API_KEY}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            onEditorChange={handleEditorUpdate}
                                            value={details}
                                            init={{
                                                height: 250,
                                                menubar: false,
                                                plugins:
                                                    'advlist link autolink lists link image  anchor ' +
                                                    'searchreplace visualblocks ' +
                                                    'media table past wordcount'
                                                ,
                                                toolbar: 'undo redo | link formatselect | fontfamily fontsize | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | outdent indent | ' +
                                                    'bullist numlist',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-center md:justify-end ">

                                <button role="button"
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full md:w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                >
                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    Submit
                                </button>

                            </div>

                            {errors.length > 0 &&
                                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                    {errors[0]}
                                </div>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default ForumPostForm;
