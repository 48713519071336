import React from "react";
import Breadcrumb from "../navigation/Breadcrumb"
import TruckIcon from "../icons/Truck"
import { Utils } from "../../utils"



const PostHeaderSection = ({post, forum, breadcrumbPages}) => {
    return (
        <div className="w-vw bg-purple-900 py-3 overflow-x-auto md:overflow-x-hidden rounded-t-md">
            <div className="mx-auto flex flex-col justify-center max-w-7xl sm:px-6 lg:px-8" >
                <div className="p">
                    <Breadcrumb 
                        id={'post-header-crumps'} 
                        pages={breadcrumbPages} 
                        textColor="text-purple-200" 
                        hoverColor="text-purple-dark"
                    />
                </div>
            </div>
        </div>
    );
};



export default PostHeaderSection;