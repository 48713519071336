import React from "react";

const PowerIcon = ({fill="none", className = "", stroke = "currentColor"}) => {
    let classes = `w-6 h-6`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" 
        //     fill={fill}
        //     viewBox="0 0 24 24" 
        //     strokeWidth={1.5} 
        //     stroke={stroke}
        //     className={classes}>
        //     <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        // </svg>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
        </svg>

    );
}

export default PowerIcon;




