export default [
    {
        "name": "Eastern Standard Time",
        "GMT": "-5",
        "code": "EST"
    },
    {
        "name": "Central Standard Time",
        "GMT": "-6",
        "code": "CT"
    },
    {
        "name": "Mountain Standard Time",
        "GMT": "-7",
        "code": "MT"
    },
    {
        "name": "Pacific Standard Time",
        "GMT": "-8",
        "code": "PT"
    }
]
