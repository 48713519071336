import React from "react";

const ReplyIcon = ({fill="none", className = "", stroke = "currentColor"}) => {
    let classes = `w-4 h-4`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={classes}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
    );
}

export default ReplyIcon;




