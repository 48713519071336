export const isAuthorised = (roles) =>{
    if(typeof roles === 'undefined' || Array.isArray(roles) === false){
        roles = [];
    }
    //get the user
    const user = getSessionData();
    if(user != null){
        return user && user.role && roles.indexOf(user.role) >= 0;
    }
    return false;
}

export const getJwtToken = () => {
    //get the user
    const user = getSessionData();
    if(user != null){
        return user.authentication.sessionToken;
    }
    return false;
}

export const destroyToken = () => {
    sessionStorage.clear();
}

export const setSession = (sesseionData) => {
    sessionStorage.setItem("user", JSON.stringify(sesseionData));
}

export const getSessionData = () => {
    const userString = sessionStorage.getItem("user");
    if(userString && userString.length > 0){
        const user = JSON.parse(userString);
        return user;
    }
    return null;
}
