import { setEndPoint, send } from "../utils/http";
import Validator from "../utils/Validator";

const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME || "";

export const validateAndSend = async (config) => {
    let configToUse = {
        ...config
    };
    if (!configToUse.endPoint ||
        Object.hasOwnProperty.call(configToUse.endPoint, 'secured') == false ||
        Object.hasOwnProperty.call(configToUse.endPoint, 'endPoint') == false ||
        Object.hasOwnProperty.call(configToUse.endPoint, 'method') == false) {
        throw new Error("Invalid endpoint configuration")
    }
    if (!configToUse.inputs) {
        configToUse.inputs = {};
    }
    if (!configToUse.rules) {
        configToUse.rules = (_ignore) => { };
    }
    if (!configToUse.formData) {
        configToUse.formData = {};
    }
    if (!configToUse.onSuccess) {
        configToUse.onSuccess = (_ignore) => { };
    }
    //validate
    let validationRules = configToUse.rules(configToUse.inputs);
    var validationResults = Validator.validateFormData(validationRules);
    if (!validationResults.isValid) {
        return [validationResults.formErrors[0]];
    }
    const response = await send(configToUse.endPoint, configToUse.formData);
    if (response instanceof Error) {
        return [response.message];
    }
    configToUse.onSuccess(response);
    if (configToUse.emit && configToUse.emit == true) {
        return response;
    }
    return true;
};

export const uploadFileToCloudinary = async (fileChangeEvent,onSuccess) => {
    if(typeof onSuccess == 'undefined'){
        onSuccess = (resp) => { return resp; };
    }
    const reader = new FileReader();
    reader.onload = async function (onLoadEvent) {
        onSuccess(onLoadEvent.target.result);
        //https://www.youtube.com/watch?v=7lhUsK-FxYI
        const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
        const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";

        const fileInput = fileChangeEvent.target;
        const formData = new FormData();
        formData.append('file', fileInput.files[0]);
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        const data = await fetch(CLOUDINARY_URL, {
            method: "POST",
            body: formData
        })
            .then(r => r.json())
            .then((data) => {
                onSuccess(data.secure_url);
            });
    }
    if (reader && reader.readAsDataURL && fileChangeEvent.target.files && fileChangeEvent.target.files.length > 0) {
        reader.readAsDataURL(fileChangeEvent.target.files[0]);
    }
}

