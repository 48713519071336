import React from "react";
import ChevronUpIcon from "../icons/ChevronUp"
import ChevronDownIcon from "../icons/ChevronDown"

const GoUpDown = () => {
    const goUp = (e) => {
        e.preventDefault();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    const goDown = (e) => {
        e.preventDefault();
        
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            left: 0,
            behavior: "smooth"
        });
    }

    

    return (
        <>
            { window.innerWidth > 768 && 
                <div id="go-up-down" style={{display:"none"}} className=" flex flex-col justify-center items-center bg-purple-900 w-fit fixed bottom-1 md:bottom-24 right-1 md:right-3">
                    <div className="cursor-pointer text-white p-2 hover:bg-purple-800 " onClick={goUp}>
                        <ChevronUpIcon /> 
                    </div>
                    <div className="cursor-pointer text-white p-2 hover:bg-purple-800 " onClick={goDown}>
                        <ChevronDownIcon />
                    </div>
                </div>
            }
        </>
    );
};



export default GoUpDown;