import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword  } from "../core/auth";
import Loader from 'react-loaders'


export default function ForgotPassord() {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const clearForm = () => {
        setEmail("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        const inputs = { email };
        let response = await forgotPassword(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        clearForm();
        const encodedEmail = btoa(email);
        navigate(`/reset-password?mb=${encodedEmail}`);
    };

    return (
        <>
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-primary-white">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <div className="flex flex-row items-center gap-x-3">
                                {/* <img
                                    className="h-10 w-auto"
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    alt="Your Company"
                                /> */}
                                <img
                                    className="h-10 w-auto"
                                    src="/logo.png"
                                    alt="wefayo"
                                />
                                <a href={isLoading ? '#' : '/'} className={isLoading ? "cursor-not-allowed" : ""}   ><span className="text-4xl font-sans"><b className="font-sans">wefayo</b></span></a>
                            </div>


                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Forgot your password ?
                            </h2>
                        </div>

                        <div className="mt-10">
                            <div>
                                <form action="#" method="POST" className="space-y-3" onSubmit={handleSubmit}>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Enter the Email Address, that you used to signup
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                value={email}
                                                disabled={isLoading}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button role="button" 
                                            type="submit"
                                            disabled={isLoading}
                                            className="flex justify-center items-center flex-row gap-x-2  w-full  rounded-md bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed"
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>

                                    </div>
                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-1">
                                            {errors[0]}
                                        </div>
                                    }
                                    <div className="text-center text-sm text-gray-300 pt-4">
                                        &nbsp;
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}
