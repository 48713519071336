import React, { useState, useEffect } from "react";
import { getLeaderboard } from "../../core/invite";
import NavUserAvatar from "../navigation/NavUserAvatar";
import LeaderBoardSkeleton from "../skeletons/LeaderBoardSkeleton";
import { useNavigate } from "react-router-dom";
import LoginAlertModal from "../auth/LoginAlertModal"
import FlagIcon from "../icons/Flag";

const LeaderBoard = ({ mode = "aside", handleStartTour = () => {} }) => {

    const navigate = useNavigate();

    const [leaderboard, setLeaderboard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const fetchLeaderboard = async () => {
            setIsLoading(true);
            const data = await getLeaderboard();
            setLeaderboard(data);
            setIsLoading(false);
        }

        fetchLeaderboard();

    }, []);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const goToProfile = (e, user) => {
        e.preventDefault();
        if (loggedInUser) {
            navigate(`/profile/${user._id}`);
        } else {
            toggleLoginAlertModalModal()
        }
    };

    return (
        <div className={`${mode === 'page' ? 'mt-2 bg-white rounded-md' : ''} flex flex-col h-fit w-full bg-slate-100 rounded-t-md `}>
            <button onClick={handleStartTour} className="flex flex-row justify-between items-center px-2 text-center text-white font-bold py-1 border-b rounded-md border-gray-200 md:border-0 bg-purple-900 hover:bg-purple-800  cursor-pointer mb-1 m-1">
                Get Started
                <FlagIcon className={"text-white"} />
            </button>

            <div id="menu-invitation-leaders" className="flex flex-row justify-between px-2 text-center font-bold py-2 border-b x-rounded-t-md border-gray-200 md:border-0 bg-primary-white">
                Invitation Leaders
            </div>
            {isLoading && <LeaderBoardSkeleton />}
            {leaderboard.length > 0 &&
                <div

                    className={`flex ${mode === 'page' ? 'flex-col rounded-b-md' : 'md:block'}  overflow-auto  md:divide-y divide-primary-gray-100  shadow-sm rounded-b-md`}
                >
                    {leaderboard.map((user, index) => (
                        <div key={index} className={`${mode === 'page' ? 'p-3' : 'p-2 md:min-w-[120px] mr-2 '} even:bg-white odd:bg-slate-100 relative flex flex-row gap-x-2 md:mr-0 justify-start hover:bg-primary-gray-50  `}>


                            <NavUserAvatar user={user} link={"#"} imgSizeClass={`${mode === 'page' ? 'w-[50px] h-[50px] max-w-[50px] max-h-[50px]' : 'w-[48px] h-[48px] max-w-[48px] max-h-[48px]'} block`} />


                            <div className=" flex flex-col justify-between flex-grow ">
                                <div className={`${mode === 'page' ? 'text-md' : 'text-xs'}  font-semibold leading-6x text-primary-gray-900`}>
                                    <div onClick={(e) => goToProfile(e, user)} className="whitespace-nowrap cursor-pointer">
                                        <div>{user.username}</div>
                                        {/* <div className="absolute inset-x-2 top-[4px] bottom-0 font-bold w-fit" >
                                            {index+1}
                                        </div> */}
                                    </div>
                                </div>
                                <div className={`${mode === 'page' ? 'mt-2 text-md  ' : 'mt-1 text-sm '}  flex  leading-1 text-gray-500 whitespace-nowrap`}>
                                    {user.statistics?.invite_user} {`invite${user.statistics?.invite_user > 1 ? "s" : ""}`}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            <LoginAlertModal isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
        </div>
    );
};

export default LeaderBoard;