import React, { useRef, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { addJob, editJob } from "../core/job_listing";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { getJobDetails } from "../core/job_listing";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { getProfile, getPublicUserProfile } from "../core/auth";
import PostListItem from "../components/post/PostListItem";
import PostSkeleton from "../components/skeletons/PostSkeleton";
import ReplySkeleton from "../components/skeletons/ReplySkeleton";
import PostHeroCard from "../components/post/PostHeroCard";
import MainLayout from "../components/layouts/MainLayout";
import ChatsIcon from "../components/icons/Chats";

const Profile = () => {

    const params = useParams();

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const DEFAULT_LOGO = "/defaultCompanyLogo.png";

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }


    const DEFAULT_AVATAR = process.env.REACT_APP_DEFAULT_AVATAR;
    const USE_DEFAULT_AVATAR = process.env.REACT_APP_USE_DEFAULT_AVATAR === "true";
    const [avatar, setAvatar] = useState(DEFAULT_AVATAR);
    const [user, setUser] = useState(null);


    const [hasAvatar, setHasAvatar] = useState(false);
    const [username, setUsername] = useState("");
    const [activeTab, setActiveTab] = useState("posts");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let userProfile = null;
            if (loggedInUser) {
                if (params && params.id && params.id === loggedInUser._id) {
                    userProfile = await getProfile();
                } else if (params && params.id && params.id !== loggedInUser._id) {
                    userProfile = await getPublicUserProfile(params.id);
                } else {
                    userProfile = await getProfile();
                }
            } else if (params && params.id) {
                userProfile = await getPublicUserProfile(params.id);
            }
            setIsLoading(false);
            const hasAvatar = userProfile && userProfile.avatar && userProfile.avatar.trim().length > 0;
            if (hasAvatar) {
                setAvatar(userProfile.avatar);
            }
            setHasAvatar(hasAvatar);

            // console.log("user", userProfile);
            // todo: handle token verification failed

            setUser(userProfile);
            setUsername(userProfile.username);
        }
        fetchData();
    }, [params]);

    const handleOnTabClick = (e, tab) => {
        e.preventDefault();
        setActiveTab(tab);
    }

    return (
        <MainLayout currentPage={"profile"}  >
            {isLoading &&
                <div className="mx-auto w-full  mb-20 md:mb-0 relative rounded-sm flex flex-col flex-1 " >
                    <PostSkeleton />
                </div>
            }
            {(!isLoading && user !== null) &&
                <div className="mx-auto w-full  mb-20 md:mb-0 relative rounded-md flex flex-col flex-1 " >

                    <div className="flex justify-between bg-primary-white font-bold text-lg rounded-t-md border-b border-gray-200 p-4">
                        <h5>{user?.role === "organisation" ? 'Organization' : 'User'} Profile</h5>
                        {(loggedInUser != null && (user?._id?.toString() !== loggedInUser._id) ) &&
                            <div className="flex items-center justify-between gap-x-6 ">
                                <a
                                    href={`/chat/${user?._id??0}`}
                                    id="menu-link-signup"
                                    className="flex flex-row items-center gap-x-2 rounded-sm bg-purple-900 px-3 py-1 text-sm font-semibold text-primary-white shadow-sm hover:bg-purple-800 "
                                >
                                    <ChatsIcon />
                                    {`Send ${username} a Message`} 
                                </a>
                            </div>
                        }
                    </div>

                    <div className="text-sm py-5 px-10 w-full flex flex-col justify-start items-start bg-white">

                        <div className="pt-x flex flex-col justify-center md:justify-start ">
                            {hasAvatar &&
                                <img
                                    className="inline-block h-16 w-16 rounded-sm "
                                    src={avatar}
                                    alt=""
                                />
                            }
                            {!hasAvatar &&
                                <div className="flex items-center justify-center md:justify-start h-16 w-16 rounded-sm uppercase font-bold text-5xl font-sans"
                                    style={{ backgroundColor: user?.name_initials_bg_color, color: user?.name_initials_text_color }}
                                >
                                    {username.length > 0 ? username[0] : ""}
                                </div>
                            }
                        </div>

                        <div className="flex  flex-col w-full md:w-50 gap-x-2 pt-2 ">

                            {(loggedInUser && params && loggedInUser._id === params.id) &&
                                <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 py-2 md:py-1">
                                    <div className="w-[200px]">
                                        Name
                                    </div>
                                    <div className="text-md font-bold capitalize">
                                        {user?.name}
                                    </div>
                                </div>
                            }

                            {(user?.role === "organisation" && loggedInUser) &&
                                <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                    <div className="w-[200px]">
                                        Company Name
                                    </div>
                                    <div className="text-md font-bold capitalize">
                                        {user?.name}
                                    </div>
                                </div>
                            }

                            {/* if organisation <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                        <div className="w-[200px]">
                                            {(user?.role === "organisation") ? <div>Contact Person</div> : <div>User Name</div>}
                                        </div>
                                        <div className=" text-md font-bold capitalize">
                                            {(user?.role === "organisation") ? (user.contact_person_name) : (user?.username)}
                                        </div>
                                    </div> */}
                            {(user?.role !== "organisation") &&
                                <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                    <div className="w-[200px]">
                                        <div>User Name</div>
                                    </div>
                                    <div className=" text-md font-bold capitalize">
                                        {user?.username}
                                    </div>
                                </div>
                            }



                            {(loggedInUser && params && loggedInUser._id === params.id) &&
                                <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                    <div className="w-[200px]">
                                        Email Address
                                    </div>
                                    <div className="text-md font-bold ">
                                        {user?.email}
                                    </div>
                                </div>
                            }



                            {/* {user?.role === "organisation" &&
                                        <>
                                            <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                                <div className="w-[200px]">
                                                    Phone Number
                                                </div>
                                                <div className="text-md font-bold ">
                                                    {user?.contact}
                                                </div>
                                            </div>

                                            <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                                <div className="w-[200px]">
                                                    Address
                                                </div>
                                                <div className="text-md font-bold ">
                                                    {user?.address}
                                                </div>
                                            </div>

                                        </>
                                    } */}


                            {/* <div className="flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                        <div className="w-[200px]">
                                            Account Type
                                        </div>
                                        <div className="mt-0 text-md font-bold capitalize ">
                                            {user?.role}
                                            {(user && user?.credentials_provider === "google") ? " Joined via google" : user?.credentials_provider}
                                        </div>
                                    </div> */}


                            <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                <div className="w-[200px]">
                                    City
                                </div>
                                <div className=" text-md font-bold capitalize ">
                                    {(user?.location.city === "404") ? "" : user?.location.city}
                                </div>
                            </div>

                            <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 border-b border-gray-200 pb-2 md:pb-1">
                                <div className=" w-[200px]">
                                    State
                                </div>
                                <div className="text-md font-bold capitalize ">
                                    {(user?.location.state === "404") ? "" : user?.location.state}
                                </div>
                            </div>

                            <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 md:border-b border-gray-200 pb-2 md:pb-1">
                                <div className="w-[200px]">
                                    Zip Code
                                </div>
                                <div className=" text-md font-bold capitalize ">
                                    {(user?.location.zipcode === "404") ? "" : user?.location.zipcode}
                                </div>
                            </div>

                            {(user?.role === "organisation") &&
                                <div className="h-[80px] md:h-auto flex flex-col md:flex-row gap-y-2 flex-grow mt-2 md:border-b border-gray-200 pb-2 md:pb-1">
                                    <div className="w-[200px]">
                                        Website URL
                                    </div>
                                    <div className=" text-md   ">
                                        <a href={user?.website_url ?? ""} className="text-purple-800" target="_blank" >{user?.website_url ?? ""}</a>
                                    </div>
                                </div>
                            }



                        </div>

                    </div>

                    {(loggedInUser && params && loggedInUser._id === params.id) &&
                        <div className="flex justify-between gap-x-2  md:gap-x-10 bg-primary-white font-bold md:text-lg border-t border-gray-200 py-4 px-4 md:px-10">

                            <a
                                href="/profile/edit"
                                className="flex justify-center cursor-pointer items-center flex-row w-[45%] md:w-[50%] h-fit md:h-auto rounded-sm bg-purple-900 px-1 md:px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                            >
                                Edit Profile
                            </a>

                            <a
                                href="/profile/password"
                                className="flex justify-center cursor-pointer items-center flex-row w-[45%] md:w-[50%] h-fit md:h-auto rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                            >
                                Update Password
                            </a>
                        </div>
                    }

                    <div className="flex justify-start bg-primary-white font-bold text-lg border-b border-gray-200 ">
                        <nav class="-mb-px flex space-x-8 px-8" aria-label="Tabs">
                            <a onClick={(e) => handleOnTabClick(e, "posts")} class={`${activeTab === "posts" ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-900 hover:border-gray-200 hover:text-gray-700"}  flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-semibold cursor-pointer`}>
                                Posts
                                <span class="bg-gray-100 text-gray-900 ml-3  rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{user?.posts?.length ?? '0'}</span>
                            </a>
                            <a onClick={(e) => handleOnTabClick(e, "comments")} class={`${activeTab === "comments" ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-900 hover:border-gray-200 hover:text-gray-700"}   flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-semibold cursor-pointer`}>
                                Comments
                                <span class="bg-gray-100 text-gray-900 ml-3  rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{user?.comments?.length ?? '0'}</span>
                            </a>
                        </nav>
                    </div>

                    <div className="text-sm rounded-b-md py-2 md:py-5 px-2 md:px-8  w-full flex flex-col justify-start items-start bg-white">
                        {activeTab === "posts" &&
                            <>
                                {user?.posts?.map((post, index) => (
                                    <PostListItem post={post} key={index} communityId={post.community} forumId={post.forum_id} />
                                ))}
                                {user?.posts?.length == 0 &&
                                    <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                        <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                            <div>
                                                No Posts
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {activeTab === "comments" &&
                            <>
                                {user?.comments.map((comment, index) => (
                                    <div className="border-gray-200 border  w-full mb-2">
                                        <a className="p-2 bg-slate-100 font-semibold flex items-center text-sm md:text-md text-purple-900 hover:underline cursor-pointer self-start"
                                            href={`/community/${comment.post.community}/forum/${comment.post.forum_id}/post/${comment.post._id}`}>
                                            {comment.post.title}
                                        </a>
                                        <PostHeroCard
                                            post={comment}
                                            isComment={true}
                                            isOwner={false}
                                            index={index}
                                            userRefs={{}}
                                            isProfileView={true}
                                        />
                                    </div>
                                ))}
                                {user?.comments?.length == 0 &&
                                    <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                        <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                            <div>
                                                No Comments
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>

                </div>
            }
        </MainLayout>
    );
};

export default Profile;
