
import React from "react";

const ChatListItemSkeleton = () => {
    return (
        <div className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200 justify-start">
            <div className="flex h-10 w-10 min-w-10 bg-gray-200 rounded-sm dark:bg-gray-700 ">
            </div>
            <div className="flex flex-col  flex-1 align-start justify-start">
                <div className="flex flex-row w-full gap-x-2">
                    <div className="h-3 w-40 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                    </div>
                </div>
                <div className="flex flex-row w-full gap-x-2">
                    <div className="h-3 w-36 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatListItemSkeleton;