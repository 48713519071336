import React, { useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import Loader from 'react-loaders'
import toast, { Toaster } from 'react-hot-toast';
import { verifyNewEmail, resendVerifyNewEmailCode } from "../core/auth";


export default function ActivateAccount() {
  let url = new URL(document.URL);
  let urlsp = url.searchParams;
  const targetEmail = urlsp.get("mb");
  let targetDecodedEmail = atob(targetEmail);
  const targetOldEmail = urlsp.get("oe");
  let targetDecodedOldEmail = atob(targetOldEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(targetDecodedOldEmail);
  const [newEmail, setNewEmail] = useState(targetDecodedEmail);
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const clearForm = () => {
    setCurrentEmail("");
    setNewEmail("");
    setCode("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setIsLoading(true);
    let inputs = { currentEmail, newEmail, code };
    let response = await verifyNewEmail(inputs);
    setIsLoading(false);
    if (response !== true) {
      return setErrors(response);
    }
    clearForm();
    navigate("/");
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    setErrors([]);
    setCode("");
    setIsLoading(true);
    setIsResendingCode(true);
    const inputs = { currentEmail, newEmail };
    let response = await resendVerifyNewEmailCode(inputs);
    setIsLoading(false);
    setIsResendingCode(false);
    if (response !== true) {
      return setErrors(response);
    }
    toast.success('Verification code has been resent to  your new email.');
  }

  return (
    <>
      <Toaster
        position="top-left"
        reverseOrder={false}
      />
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-primary-white">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex flex-row items-center gap-x-3">
                <img
                  className="h-10 w-auto"
                  src="/logo.png"
                  alt="wefayo organization"
                />
                <a href={isLoading ? '#' : '/'} className={"" + (isLoading ? "cursor-not-allowed" : "")} ><span className="text-4xl font-sans"><b className="font-sans">wefayo</b></span></a>
              </div>


              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Verify your new email address
              </h2>
              <p className="mt-2 text-md leading-6 text-gray-500">
                To successfully update your email, Please enter the code sent to
                <span className="text-purple-900 mx-2 font-bold">{newEmail}</span>
              </p>
              <p className="mt-4 text-md leading-6 text-gray-500">
                If you didn't receive this email, click here to {' '}
                <button role="button"  type="button" disabled={isLoading} onClick={handleResendCode} className={`flex justify-center items-center flex-row  font-semibold  disabled:cursor-not-allowed ${isResendingCode ? "bg-purple-400 text-white px-3 rounded-sm " : "text-purple-900 hover:text-purple-500"}`}>

                  {isResendingCode && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}

                  {isResendingCode ? 'Resending Code' : 'Resend The Code'}
                </button>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-3" onSubmit={handleSubmit}>
                  
                  <div>
                    <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
                      Enter Code <span className="text-red-900 mx-1">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="code"
                        name="code"
                        type="code"
                        autoComplete="current-password"
                        required
                        disabled={isLoading}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="font-bold block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                      />
                    </div>
                  </div>

                  <div>
                    <button role="button" 
                      type="submit"
                      disabled={isLoading}
                      className="flex justify-center items-center flex-row w-full rounded-md bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed "
                    >
                      {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                      Verify New Email
                    </button>
                  </div>
                </form>
                {errors.length > 0 &&
                  <div className="app-error text-red-400 font-bold text-xs py-1">
                    {errors[0]}
                  </div>
                }
              </div>


            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
