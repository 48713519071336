import React, { useRef, useEffect, useState } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import states from "../utils/states.json";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import { updateProfile, getProfile } from "../core/auth";
import MainLayout from "../components/layouts/MainLayout";


const ProfileEdit = () => {

    const DEFAULT_LOGO = "/defaultCompanyLogo.png";

    // Editing a job
    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    loggedInUser = JSON.parse(loggedInUserStr);


    const DEFAULT_AVATAR = process.env.REACT_APP_DEFAULT_AVATAR;
    const USE_DEFAULT_AVATAR = process.env.REACT_APP_USE_DEFAULT_AVATAR === "true";

    const [role, setRole] = useState(loggedInUser.role);

    const [isLoading, setIsLoading] = useState(false);
    const [avatar, setAvatar] = useState(loggedInUser.avatar);
    const nameParts = loggedInUser && loggedInUser.name ? loggedInUser.name.split(" ") : [];
    const [firstName, setFirstName] = useState(nameParts.length > 0 ? nameParts[0] : "");
    const [lastName, setLastName] = useState(nameParts.length > 1 ? nameParts[1] : "");

    const [companyLogoUrl, setCompanyLogoUrl] = useState(loggedInUser.avatar);
    const [companyName, setCompanyName] = useState(loggedInUser.name);
    const [contactPerson, setContactPerson] = useState(loggedInUser.contact_person_name);
    const [companyContact, setCompanyContact] = useState(loggedInUser.contact);
    const [companyAddress, setCompanyAddress] = useState(loggedInUser.address);
    const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState(loggedInUser?.website_url ?? "");



    const [username, setUsername] = useState(loggedInUser.username);
    const [email, setEmail] = useState("");
    const [city, setCity] = useState(loggedInUser.location.city === "404" ? "" : loggedInUser.location.city);
    const [state, setState] = useState(loggedInUser.location.state === "404" ? "" : loggedInUser.location.state);
    const [zipcode, setZipcode] = useState(loggedInUser.location.zipcode === "404" ? "" : loggedInUser.location.zipcode);
    const checkAvatar = loggedInUser && loggedInUser.avatar && loggedInUser.avatar.trim().length > 0;
    const [hasAvatar, setHasAvatar] = useState(checkAvatar);
    const [user, setUser] = useState(loggedInUser);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const [isFileUpLoading, setIsFileUpLoading] = useState(false);
    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const handleOnFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setAvatar(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setAvatar(data.secure_url);
                    setHasAvatar(true);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    const handleOnLogoFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setCompanyLogoUrl(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setCompanyLogoUrl(data.secure_url);
                    setHasAvatar(true);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    const clearForm = () => {
        setAvatar(DEFAULT_AVATAR);
        setFirstName("");
        setLastName("");
        setCompanyLogoUrl(DEFAULT_LOGO);
        setCompanyName("");
        setCompanyContact("");
        setCompanyAddress("");
        setContactPerson("");
        setUsername("");
        setEmail("");
        setCity("");
        setState("");
        setZipcode("");
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        let inputs = {
            avatar,
            firstName,
            lastName,
            username,
            email,
            password,
            password2,
            state,
            city,
            zipcode,
            role,
            companyLogoUrl,
            companyName,
            companyContact,
            companyAddress,
            companyWebsiteUrl,
            contactPerson
        };
        if (USE_DEFAULT_AVATAR === false && avatar === DEFAULT_AVATAR) {
            inputs.avatar = "";
        }
        if (inputs.role == "organisation") {
            inputs.username = inputs.companyName;
        }
        let response = await updateProfile(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        navigate(`/profile/${loggedInUser._id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            const userProfile = await getProfile();

            const nameParts = userProfile.name ? userProfile.name.split(" ") : [];
            setFirstName(nameParts.length > 0 ? nameParts[0] : "");
            setLastName(nameParts.length > 1 ? nameParts[1] : "");
            setCompanyLogoUrl(userProfile.avatar);
            setCompanyName(userProfile.name);
            setCompanyContact(userProfile.contact);
            setCompanyAddress(userProfile.address);
            setUsername(userProfile.username);
            setEmail(userProfile.email);
            setCity(userProfile.location.city === "404" ? "" : userProfile.location.city);
            setState(userProfile.location.state === "404" ? "" : userProfile.location.state);
            setZipcode(userProfile.location.zipcode === "404" ? "" : userProfile.location.zipcode);
            const hasAvatar = userProfile && userProfile.avatar && userProfile.avatar.trim().length > 0;
            setHasAvatar(hasAvatar);
            setUser(userProfile);
        }
        fetchData();
    }, []);

    return (
        <MainLayout currentPage={"profile"}  >
            {user &&
                <div className="mx-auto relative rounded-md flex flex-col  w-full " >

                    <div className="flex justify-center bg-primary-white rounded-t-md font-bold text-lg border-b border-gray-200 p-4">
                        <h5>Edit Your Profile </h5>
                    </div>

                    <div className="p-5  flex flex-col rounded-b-md bg-white">

                        <div className="mt-5">
                            <div>
                                <form method="POST" className="space-y-2" onSubmit={handleSubmit}>

                                    {(role === "member" || role === "admin") &&
                                        <>
                                            <div className="">
                                                <div className="pt-5 flex items-center w-full gap-x-3">

                                                    <div className="pt-x flex flex-col ">
                                                        {hasAvatar &&
                                                            <img
                                                                className="inline-block h-16 w-16 rounded-sm"
                                                                src={avatar}
                                                                alt=""
                                                            />
                                                        }
                                                        {!hasAvatar &&
                                                            <div className="flex items-center justify-center h-16 w-16 rounded-sm uppercase font-bold text-5xl font-sans"
                                                                style={{ backgroundColor: user?.name_initials_bg_color, color: user?.name_initials_text_color }}
                                                            >
                                                                {username.length > 0 ? username[0] : ""}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="pt-2 flex flex-col flex-grow self-stretch justify-end items-start">
                                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                                            Avatar
                                                        </label>

                                                        <div className="flex items-center justify-center bg-grey-lighter mt-2">
                                                            <label disabled={isLoading} htmlFor="select-avatar" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                                </svg>
                                                                <span className={`text-xs font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                                    {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                                    {(!avatar || avatar == DEFAULT_AVATAR) ? 'Select' : 'Change'} Profile Photo
                                                                </span>
                                                                <input id="select-avatar" disabled={isLoading} name="select-avatar" type='file' className="hidden" onChange={handleOnFileChange} />
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="flex flex-row w-full gap-x-2 pt-2">
                                                <div className="flex flex-col flex-grow">
                                                    <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                                        First Name
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            id="firstname"
                                                            name="firstname"
                                                            type="text"
                                                            autoComplete=""
                                                            required
                                                            maxLength={100}
                                                            value={firstName}
                                                            disabled={isLoading}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            className="font-bold mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex flex-col  flex-grow">
                                                    <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Last Name
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            id="lastname"
                                                            name="lastname"
                                                            type="text"
                                                            autoComplete=""
                                                            required
                                                            maxLength={100}
                                                            value={lastName}
                                                            disabled={isLoading}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            className="font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {role === "organisation" &&
                                        <>


                                            {/* company logo url */}
                                            <div className="">
                                                <div className="flex items-start w-full gap-x-3">

                                                    <div className="flex flex-col pt-2">
                                                        <img
                                                            className="inline-block h-16 w-16 rounded-sm"
                                                            src={companyLogoUrl}
                                                            alt=""
                                                        />
                                                    </div>


                                                    <div className="flex flex-col flex-grow self-stretch justify-end items-start">
                                                        <div className={"relative"}>

                                                        </div>

                                                        <div className="flex flex-col items-start justify-start bg-grey-lighter pt-1">
                                                            <label htmlFor="job-company-address" className="block flex flex-row justify-start items-start   text-sm font-medium leading-6 text-gray-900 mb-2">
                                                                Company Logo
                                                            </label>
                                                            <label disabled={isLoading} htmlFor="select-avatar" className={"flex flex-row items-center px-3 py-1 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                                </svg>
                                                                <span className={`text-sm font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                                    {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                                    {(!companyLogoUrl || companyLogoUrl == DEFAULT_LOGO) ? 'Select' : 'Change'} Company Logo
                                                                </span>
                                                                <input id="select-avatar" disabled={isLoading} name="select-avatar" type='file' className="hidden" onChange={handleOnLogoFileChange} />
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="flex flex-col md:flex-row justify-between gap-x-2 pt-5">
                                                {/* company name */}
                                                <div className="flex-grow">
                                                    <label htmlFor="job-company-name" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        Company Name
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            type="text"
                                                            name="job-company-name"
                                                            id="job-company-name"
                                                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                            required
                                                            maxLength={100}
                                                            value={companyName}
                                                            disabled={isLoading}
                                                            onChange={(e) => setCompanyName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex-grow">
                                                    <label htmlFor="contactPerson" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        Contact Person
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            type="text"
                                                            name="contactPerson"
                                                            id="contactPerson"
                                                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                            required
                                                            maxLength={100}
                                                            value={contactPerson}
                                                            disabled={isLoading}
                                                            onChange={(e) => setContactPerson(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="flex flex-col md:flex-row justify-between gap-x-2">


                                                <div className="flex-grow">
                                                    <label htmlFor="company-contact" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        Company Phone Number
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            type="text"
                                                            name="company-contact"
                                                            id="company-contact"
                                                            required
                                                            maxLength={100}
                                                            value={companyContact}
                                                            disabled={isLoading}
                                                            placeholder="e.g 000-000-0000"
                                                            onChange={(e) => setCompanyContact(e.target.value)}
                                                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>

                                                {/* physical address */}
                                                <div className="flex-grow">
                                                    <label htmlFor="job-company-address" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        Company Address
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            type="text"
                                                            name="job-company-address"
                                                            id="job-company-address"
                                                            required
                                                            maxLength={100}
                                                            value={companyAddress}
                                                            disabled={isLoading}
                                                            onChange={(e) => setCompanyAddress(e.target.value)}
                                                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col md:flex-row justify-between gap-x-2">
                                                <div className="flex-grow">
                                                    <label htmlFor="company-website-url" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        Website URL
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            type="text"
                                                            name="company-website-url"
                                                            id="company-website-url"
                                                            value={companyWebsiteUrl}
                                                            disabled={isLoading}
                                                            onChange={(e) => setCompanyWebsiteUrl(e.target.value)}
                                                            className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {(role === "member" || role === "admin") &&
                                        <div>
                                            <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                User Name
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <input
                                                    id="username"
                                                    name="username"
                                                    type="text"
                                                    autoComplete=""
                                                    required
                                                    maxLength={100}
                                                    value={username}
                                                    disabled={isLoading}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="flex flex-col flex-grow">
                                        <label htmlFor="city" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                            City
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-0">
                                            <input
                                                id="city"
                                                name="city"
                                                type="text"
                                                autoComplete=""
                                                required
                                                maxLength={100}
                                                value={city}
                                                disabled={isLoading}
                                                onChange={(e) => setCity(e.target.value)}
                                                className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>


                                    <div className="flex flex-col md:flex-row  w-full gap-x-2 pb-2">

                                        <div className="flex flex-col flex-grow">
                                            <label htmlFor="state" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                State
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <select
                                                    id="state"
                                                    name="state"
                                                    type="text"
                                                    autoComplete=""
                                                    required
                                                    value={state}
                                                    disabled={isLoading}
                                                    onChange={(e) => setState(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                >
                                                    <option value="" key="first-state" selected disabled >Select State</option>
                                                    {states.map((state) => {
                                                        return (
                                                            <option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex flex-col md:w-[100px]">
                                            <label htmlFor="zipcode" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                Zip Code
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <input
                                                    id="zipcode"
                                                    name="zipcode"
                                                    type="text"
                                                    autoComplete=""
                                                    required
                                                    value={zipcode}
                                                    disabled={isLoading}
                                                    maxLength={5}
                                                    onChange={(e) => setZipcode(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>


                                    </div>

                                    {/* <div className="flex flex-row gap-x-2">
                                    <div className="flex-grow">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            Current Account Password
                                        </label>
                                        <div className="mt-0">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                maxLength={100}
                                                value={password}
                                                disabled={isLoading}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                </div> */}

                                    <div className="pt-10 justify-between flex">
                                        <a
                                            href="/profile"
                                            className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                        >
                                            Cancel
                                        </a>


                                        <button role="button"
                                            type="submit"
                                            disabled={isLoading || isFileUpLoading}
                                            className="flex justify-center items-center flex-row w-1/2 rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Save Changes
                                        </button>
                                    </div>
                                </form>
                                {errors.length > 0 &&
                                    <div className="app-error text-red-400 font-bold text-xs py-1">
                                        {errors[0]}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </MainLayout>
    );
};

export default ProfileEdit;
