import React, { useEffect } from "react";
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Forum from "./pages/Forum";
import Replies from "./pages/Replies";
import Post from "./pages/Post";
import CommunityRequestForm from "./pages/CommunityRequestForm";
import ForumPostForm from "./pages/ForumPostForm";
import Search from "./pages/Search";
import Jobs from "./pages/Jobs";
import JobDetails from "./pages/JobDetails";
import JobForm from "./pages/JobForm";
import JobManagement from "./pages/JobManagement";
import ActivateAccount from "./pages/ActivateAccount";
import JoinViaLinkInvitation from "./pages/JoinViaLinkInvitation";
import ForgotPassord from "./pages/ForgotPassord";
import ResetPassord from "./pages/ResetPassord";
import ActivateNewEmail from "./pages/ActivateNewEmail";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import ProfilePassword from "./pages/ProfilePassword";
import EditCommunity from "./pages/EditCommunity";
import AddCommunityForumForm from "./pages/AddCommunityForumForm";
import EditForum from "./pages/EditForum";
import EditPostForm from "./pages/EditPostForm";
import About from "./pages/About";
import Support from "./pages/Support";
import TicketDetails from "./pages/TicketDetails";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ForumAds from "./pages/ForumAds";
import ForumAdsForm from "./pages/ForumAdsForm";
import LeaderBoards from "./pages/LeaderBoards";
import Events from "./pages/Events";
import AddEventForm from "./pages/AddEventForm";
import EventDetails from  "./pages/EventDetails";
import Chat from "./pages/Chat";

const App = () => {

    useEffect(() => {    
        window.onpageshow = function(event) {
          if (event.persisted) {
            window.location.reload();
          }
        };
     }, []);

    return (
        <div className="bg-primary-gray h-full">
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/activate' element={<ActivateAccount />} />
                    <Route path='/join' element={<JoinViaLinkInvitation />} />
                    <Route path='/forgot-password' element={<ForgotPassord />} />
                    <Route path='/reset-password' element={<ResetPassord />} />
                    {/* todo: feature update */}
                    {/* <Route path='/account-settings' element={<AccountSettings />} /> */}
                    <Route path='/activate-new-email' element={<ActivateNewEmail />} />
                    <Route path='/community/:comid/edit' element={<EditCommunity />} />
                    <Route path='/community/:comid/forum/:id' element={<Forum />} />
                    <Route path='/community/:comid/forum/:id/edit' element={<EditForum />} />
                    <Route path='/community/:comid/forums/add' element={<AddCommunityForumForm />} />
                    <Route path='/community/:comid/forum/:id/post/:pid' element={<Post />} />
                    <Route path='/community/:comid/forum/:id/post/:pid/edit' element={<EditPostForm />} />
                    <Route path='/jobs/:id' element={<JobDetails />} />
                    <Route path='/community-request-form' element={<CommunityRequestForm />} />
                    <Route path='/forum-post-form/:comid/:id' element={<ForumPostForm />} />
                    <Route path='/search' element={<Search />} />
                    <Route path='/jobs' element={<Jobs />} />
                    <Route path='/jobs/add' element={<JobForm />} />
                    <Route path='/jobs/edit/:id' element={<JobForm isEdit={true} />} />
                    <Route path='/jobs/manage' element={<JobManagement />} />
                    <Route path='/:id/replies' element={<Replies />} />
                    <Route path='/profile/:id' element={<Profile />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/profile/edit' element={<ProfileEdit />} />
                    <Route path='/profile/password' element={<ProfilePassword />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/support' element={<Support />} />
                    <Route path='/support/:id' element={<TicketDetails />} />
                    <Route path='/terms-of-use' element={<TermsOfUse />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/forumads' element={<ForumAds />} />
                    <Route path='/forumads/add/:id' element={<ForumAdsForm />} />
                    <Route path='/forumads/add/' element={<ForumAdsForm />} />
                    <Route path='/leader-boards' element={<LeaderBoards />} />
                    <Route path='/events' element={<Events />} />
                    <Route path='/events/:id' element={<EventDetails />} />
                    <Route path='/events/add' element={<AddEventForm/>} />
                    <Route path='/events/edit/:id' element={<AddEventForm/>} />
                    <Route path='/658319afbba400104538980c' element={<Chat/>} />
                    <Route path='/chat/:other_user_id' element={<Chat/>} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
