import React, { Fragment, useEffect, useRef, useState } from "react";

const ReplySkeleton = ({ items = 5 }) => {
    return (
        <div role="status" className="flex flex-col mb-2 w-full animate-pulse bg-gray-100 mb-5 mt-5">
            <div className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200 justify-start">
                <div className="flex h-16 w-16 min-w-16 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
                <div className="flex flex-col  flex-1 align-start justify-start">
                    <div className="flex flex-row w-full gap-x-2">
                        <div className="h-3 w-3 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                        <div className="h-3 w-40 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-x-2">
                        <div className="h-3 w-3 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                        <div className="h-3 w-36 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-x-2">
                        <div className="h-3 w-3 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                        <div className="h-3 w-24 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full p-2 gap-x-2 border-b border-gray-200 justify-start">
                <div className="flex flex-row justify-between">
                    <div className="flex h-3 w-24  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="flex h-3 w-40 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                </div>
                <div className="flex flex-col mt-5 gap-y-3">
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-2/3  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                </div>
            </div>
            <div className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200 justify-end">
                <div className="h-8 w-24 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
                <div className="h-8 w-24  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default ReplySkeleton;

