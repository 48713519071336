import React, { useRef } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Avatar from "../components/icons/Avatar";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import LikeIcon from "../components/icons/Like";
import { Editor } from '@tinymce/tinymce-react';
import ForumHeroSection  from '../components/forum/ForumHeroSection';
import { Utils } from "../utils";
import { useParams } from "react-router-dom";
import { useState } from 'react';
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import MainLayout from "../components/layouts/MainLayout";

const JobForm = () => {

    
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
          console.log(editorRef.current.getContent());
        }
    };

    const [companyLogoUrl, setCompanyLogoUrl] = useState('');
    const handleChange = (event) => {
        setCompanyLogoUrl(event.target.value);
    };

    const community = labData.communities[0];


    return (
        <MainLayout currentPage={"jobs"} >
            <JobListingCommunityAccordion
                community={community}
                isOpenInitially={true}
                isAdmin={true}
            />
        </MainLayout>
    );
};

export default JobForm;
