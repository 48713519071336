export const forgotPassowrdRules = ({email}) => {
    return {
        email: {
            errors: [],
            value: email,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address: Cannot be more than 60 characters",
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: At least 5 characters are required",
                // },
                emailFormat: "Email address: Invalid email address",
            },
        }
    }
}