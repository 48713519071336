import React, { useState, useEffect } from "react";

const ForumAvatar = ({ forum }) => {
    const colors = [
        "bg-blue-200 text-yellow-700",
        "bg-green-200 text-green-700",
        "bg-yellow-200 text-yellow-700",
        "bg-blue-200 text-yellow-700",
        "bg-red-200  text-red-700", 
        "bg-purple-200 text-purple-700"
    ];
    const rand = Math.floor(Math.random() * (colors.length -1)) + 1;
    const color = colors[rand];
    let letter = forum.title ? forum.title.trim() : " ";
    letter = letter[0];
    return (
        <>
            {forum.icon.trim().length == 0 && 
                <div className={`${color} flex items-center justify-center h-full w-full rounded-sm uppercase font-bold text-xl font-sans`}> 
                    {letter}
                </div>
            }
            {forum.icon.trim().length > 0 && 
                <img
                    className="inline-block  w-[48px] h-[48px] max-w-[48px] max-h-[48px] rounded-sm "
                    src={forum.icon}
                    alt=""
                />
            }
        </>
    );
};

export default ForumAvatar;