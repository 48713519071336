import { validateAndSend } from "../index";
import { isAuthorised } from "../../utils/auth";

import {
    addForumAdEndPoint,
} from "../endPoints";

import { addForumAdRules } from "./addForumAdRules";

export const addForumAd = async(inputs) => {
    return validateAndSend({
        endPoint: addForumAdEndPoint,
        inputs: inputs,
        // rules: addForumAdRules,
        formData: {
            user_id: inputs.userId,
            forum_id: inputs.forumId,
            status: inputs.status,
            title: inputs.title,
            url: inputs.url,
            posted_date: inputs.postedDate,
            expiry_date: inputs.expiryDate,
            media: inputs.media,
            description: inputs.description
        }
    });
}
