import { validateAndSend } from "../index";
import { isAuthorised } from "../../utils/auth";

import {
    eventsEndPoint,
    addEventEndPoint,
    getEventDetailsEndPoint,
    editEventEndPoint,
    deleteEventEndPoint,
    searchOrganisationEventsEndPoint,
    searchEventsEndPoint
} from "../endPoints";

import { addEventRules } from "./addEventRules";

export const getEvents = async () => {
    return validateAndSend({
        endPoint: eventsEndPoint,
        emit: true
    });
}


export const addEvent = async(inputs) => {
    return validateAndSend({
        endPoint: addEventEndPoint,
        inputs: inputs,
        rules: addEventRules,
        formData: {
            ... inputs,
        }
    });
}

export const getEvent = async (id) => {
    const endPoint = getEventDetailsEndPoint(id);
    return validateAndSend({
        endPoint: endPoint,
        emit: true
    });
}

export const editEvent = async (inputs, id) => {
    return validateAndSend({
        endPoint: editEventEndPoint,
        inputs: inputs,
        rules: addEventRules,
        formData: {
            ... inputs,
        }
    });
}

export const deleteEvent = async (inputs) => {
    return validateAndSend({
        endPoint: deleteEventEndPoint,
        formData: {
            id: inputs.id
        }
    });
}


export const searchEvents = async(inputs) => {
    let endPoint = null;
    if(isAuthorised(["organisation"])){
        endPoint = searchOrganisationEventsEndPoint;
    }else{
        endPoint = searchEventsEndPoint;
    }
    
    return validateAndSend({
        endPoint: endPoint,
        inputs: inputs,
        // rules: searchJobRules,
        formData: {
            ... inputs
        },
        emit: true
    });
}