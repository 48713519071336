export const addSupportRequestRules = (inputs) => {
    return {
        title: {
            errors: [],
            value: inputs.title,
            isValid: null,
            validations: {
                required: {
                    error: "Title is required",
                    trim: true
                },
                maxLength: {
                    value: 300,
                    error: "Title cannot be more than 300 characters"
                },
                // minLength: {
                //     value: 10,
                //     error: "Title: at least 10 characters are required"
                // }
            }
        },
        details: {
            errors: [],
            value: inputs.details,
            isValid: null,
            validations: {
                // maxLength: {
                //     value: 10000,
                //     error: "Details: cannot be more than 10,000 characters"
                // }
            }
        },
        category: {
            errors: [],
            value: inputs.category,
            isValid: null,
            validations: {
                required: {
                    error: "Category is required",
                    trim: true
                }
            }
        },
    }
}

export const respondToSupportRequestRules = (inputs) => {
    return {
        details: {
            errors: [],
            value: inputs.details,
            isValid: null,
            validations: {
                required: {
                    trim: true,
                    error: "Please provide a response"
                },
                // maxLength: {
                //     value: 10000,
                //     error: "Details: cannot be more than 10,000 characters"
                // }
            }
        }
    }
}