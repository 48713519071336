import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import TruckIcon from "../icons/Truck";
import { Utils } from "../../utils";
import ReactTimeAgo from 'react-time-ago';
import labData from "../../utils/labData";
import ChevronRightIcon from "../icons/ChevronRight";
import { getCommunities } from "../../core/communities";
import ForumAvatar from "../forum/ForumAvatar";

const SelectForumModal = ({isOpen, closeFun}) => {

    // const [open, setOpen] = useState(isOpen);
    const cancelButtonRef = useRef(null);

    const [forums, setForums] = useState([]);
    const [renderedForums, setRenderedForums] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        submitSearchForm();
    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitSearchForm();
        }
    };
    const submitSearchForm = async () => {
        const searchBy  = searchTerm.trim().toLowerCase();
        let found = [];
        for (let index = 0; index < forums.length; index++) {
            const forum = forums[index];
            const title = forum.title?forum.title.trim().toLowerCase():"";
            const description = forum.description?forum.description.trim().toLowerCase():"";
            const parts = searchBy.split(" ");
            for (let index2 = 0; index2 < parts.length; index2++) {
                const part  = parts[index2];
                if(title.indexOf(part) >= 0){
                    found.push(forum);
                    break;
                }else if(description.indexOf(part) >= 0){
                    found.push(forum);
                    break;
                }
            }
        }
        setRenderedForums(found);
    }

    useEffect(() => {
        const fetchForums = async () => {
            const data = await getCommunities();
            let temp = [];
            if(data){
                for (let i = 0; i < data?.length; i++) {
                    for (let j = 0; data[i]?.forums && j < data[i]?.forums.length; j++) {
                        let forum = {...data[i].forums[j]};
                        forum["communityId"] = data[i]._id;
                        forum["communityTitle"] = data[i].title;
                        temp.push(forum);
                    }
                }
            }
            setForums(temp);
            setRenderedForums(temp);
        }
        fetchForums();
    }, []);


    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative " style={{zIndex: 99}} initialFocus={cancelButtonRef} open={isOpen} onClose={closeFun}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                    <div onClick={()=>closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full  md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <Dialog.Panel className="h-[96vh] md:h-auto relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56 ">
                            
                            <div className="bg-white mx-0 md:mx-4 rounded-sm">
                                <div className="bg-primary-white flex flex-row justify-between p-3 rounded-t-sm  md:items-center ">
                                    <div className="text-gray-900 text-md font-bold">
                                        Select A Forum
                                    </div>
                                    <div className="flex flex-row items-center">   
                                        <div className="hidden md:block" >
                                            <label htmlFor="search" className="sr-only">
                                                Search
                                            </label>
                                            <div className="relative text-gray-400 focus-within:text-gray-600" >
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    id="search"
                                                    className="block  md:w-[400px] rounded-full border-gray-200  bg-gray-300 py-1 pl-10 pr-3 text-gray-900 mb-0 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                                    placeholder="Search"
                                                    type="search"
                                                    name="search"
                                                    onChange={handleChange}
                                                    onKeyDown={handleKeyDown}
                                                    value={searchTerm}
                                                />
                                            </div> 
                                        </div>
                                        <div className="ml-4 mr-1 text-gray-900 font-bold cursor-pointer hover:text-purple-200" onClick={()=>closeFun(false)}>
                                            X
                                        </div>
                                    </div>
                                </div>
                                <div className="md:hidden bg-primary-white flex flex-row justify-between p-3 md:items-center ">
                                    <div className="md:block text-gray-900 flex w-full" >
                                        <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="relative w-full text-gray-400 focus-within:text-gray-600" >
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                            </div>
                                            <input
                                                id="search"
                                                className="block w-full rounded-full border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-md leading-6 disabled:cursor-progress"
                                                placeholder="Search"
                                                type="search"
                                                name="search"
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                                value={searchTerm}
                                            />
                                        </div> 
                                    </div>
                                </div>

                                <div className="h-[350px] overflow-x-auto rounded-b-sm">
                                    {renderedForums.map((forum, index) => (
                                        <a key={index} href={`/forum-post-form/${forum.communityId}/${forum._id}`} className={`cursor-pointer  flex flex-row justify-between  border-gray-200 px-3 pt-3 pb-3 even:bg-white odd:bg-slate-100 hover:bg-purple-100  ${index == (forums.length -1)?'rounded-b-sm':''}`}>
                                            <div className="min-w-[48px] w-[48px] min-h-[48px] h-[48px] bg-purple-900x flex justify-center items-center text-purple-300">
                                                <ForumAvatar forum={forum} />
                                            </div>
                                            <div className="flex-grow flex flex-col justify-between ml-2">
                                                <div className="flex flex-col md:flex-row  md:justify-between md:items-center">
                                                    <span className="text-md font-semibold font-medium">
                                                        {forum.title}
                                                    </span>

                                                    <b className="text-gray-400  text-xs pr-8">{forum.communityTitle}</b>
                                                </div>
                                                <div className="text-muted flex flex-row items-center justify-between">
                                                    <div className="">
                                                        {forum.description} 
                                                    </div>
                                                    <div className="text-gray-200 pr-1">
                                                        <ChevronRightIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                            
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};



export default SelectForumModal;