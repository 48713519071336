export const loginRules = ({email, password}) => {
    return {
        email: {
            errors: [],
            value: email,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address: Cannot be more than 60 characters",
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: At least 5 characters are required",
                // },
                emailFormat: "Email address: Invalid email address",
            },
        },
        password: {
            errors: [],
            value: password,
            validations: {
                required: {
                    trim: false,
                    error: "Password is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Password: Cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Password: At least 6 characters are required",
                // },
            },
        }
    }
}