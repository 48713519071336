export const updateCommunityRules = (inputs) => {
    return {
        title: {
            errors: [],
            value: inputs.title,
            isValid: null,
            validations: {
                required: {
                    error: "Title is required",
                    trim: true
                },
                maxLength: {
                    value: 300,
                    error: "Title cannot be more than 300 characters"
                },
                // minLength: {
                //     value: 10,
                //     error: "Title: at least 10 characters are required"
                // }
            }
        },
        description: {
            errors: [],
            value: inputs.description,
            isValid: null,
            validations: {
                // maxLength: {
                //     value: 10000,
                //     error: "Description: cannot be more than 10,000 characters"
                // }
            }
        }
    }
}