import { validateAndSend } from "../index";
import { isAuthorised } from "../../utils/auth";

import {
    getAllJobsEndPoint,
    getActiveJobsEndPoint,
    viewJobDetailsEndPoint,
    getMyCompaniesEndPoint,
    addJobEndPoint,
    trackApplyClickEndPoint,
    editJobEndPoint,
    getOrganisationJobsEndPoint,
    searchJobEndPoint,
    searchOrganisationJobEndPoint,
    checkIsJobSavedEndPoint,
    saveJobEndPoint,
    unSaveJobEndPoint
} from "../endPoints";

import { addJobRules } from "./addJobRules";
import { trackApplyJobRules } from "./trackApplyJobRules";
import { searchJobRules } from "./searchJobRules";

export const getJobListing = async () => {
    let endPoint = null;
    if(isAuthorised(["admin"])){
        endPoint = getAllJobsEndPoint;
    }else if(isAuthorised(["organisation"])){
        endPoint = getOrganisationJobsEndPoint;
    }else{
        endPoint = getAllJobsEndPoint;
    }

    return validateAndSend({
        endPoint: endPoint,
        emit: true
    });
}

export const getJobDetails = async (id) => {
    return validateAndSend({
        endPoint: viewJobDetailsEndPoint(id),
        emit: true
    });
}

export const getMyCompanies = async(id) => {
    return validateAndSend({
        endPoint: getMyCompaniesEndPoint,
        emit: true
    })
}

export const addJob = async(inputs) => {
    return validateAndSend({
        endPoint: addJobEndPoint,
        inputs: inputs,
        rules: addJobRules,
        formData: {
            ... inputs,
            skills: btoa(encodeURIComponent(inputs.skills)),
            qualifications: btoa(encodeURIComponent(inputs.qualifications)),
            specialAccomodations: btoa(encodeURIComponent(inputs.specialAccomodations)),
            details: btoa(encodeURIComponent(inputs.details))
        }
    });
}

export const trackClickApply = async(inputs) => {
    return validateAndSend({
        endPoint: trackApplyClickEndPoint,
        inputs: inputs,
        rules: trackApplyJobRules,
        formData: {
            user_id: inputs.userId,
            job_id: inputs.jobId
        }
    });
}

export const editJob = async(inputs) => {
    return validateAndSend({
        endPoint: editJobEndPoint,
        inputs: inputs,
        rules: addJobRules,
        formData: {
            ... inputs,
            skills: btoa(encodeURIComponent(inputs.skills)),
            qualifications: btoa(encodeURIComponent(inputs.qualifications)),
            specialAccomodations: btoa(encodeURIComponent(inputs.specialAccomodations)),
            details: btoa(encodeURIComponent(inputs.details))
        }
    });
}

export const searchJobs = async(inputs) => {
    let endPoint = null;
    if(isAuthorised(["organisation"])){
        endPoint = searchOrganisationJobEndPoint;
    }else{
        endPoint = searchJobEndPoint;
    }
    
    return validateAndSend({
        endPoint: endPoint,
        inputs: inputs,
        rules: searchJobRules,
        formData: {
            ... inputs
        },
        emit: true
    });
}

export const getIsJobSaved = async(id) => {
    return validateAndSend({
        endPoint: checkIsJobSavedEndPoint(id),
        emit: true
    });
}

export const saveJob = async(inputs) => {
    return validateAndSend({
        endPoint: saveJobEndPoint,
        inputs: inputs,
        formData: {
            ... inputs
        },
        emit: true
    });
}

export const unSaveJob = async(inputs) => {
    return validateAndSend({
        endPoint: unSaveJobEndPoint,
        inputs: inputs,
        formData: {
            ... inputs
        },
        emit: true
    });
}