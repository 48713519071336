import React from "react";

const MagnifyingGlass = ({fill="none", className = "", stroke = "currentColor"}) => {
    let classes = `w-6 h-6`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill={fill}
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke={stroke}
            className={classes}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>

    );
}

export default MagnifyingGlass;




