export const accountActivationRules = ({email, code}) => {
    return {
        code: {
            errors: [],
            value: code,
            isValid: null,
            validations: {
              required: "Activation Code is required",
              length: {
                value: 6,
                error: "Activation Code should be 6 numbers",
              }
            }
        },
        email: {
            errors: [],
            value: email,
            isValid: null,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address cannot be more than 60 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid email address"
            }
        },
    }
}