export const acceptInvitationRules = ({firstName, lastName, username, email, password, password2, state, city, zipcode}) => {
    return {
        firstName: {
            errors: [],
            value: firstName,
            isValid: null,
            validations: {
                required: "First name is required",
                maxLength: {
                    value: 30,
                    error: "Cannot be more than 30 characters"
                },
                // minLength: {
                //     value: 3,
                //     error: "At least 3 characters are required"
                // }
            }
        },
        lastName: {
            errors: [],
            value: lastName,
            isValid: null,
            validations: {
                required: "Last name is required",
                maxLength: {
                    value: 30,
                    error: "Cannot be more than 30 characters"
                },
                // minLength: {
                //     value: 3,
                //     error: "At least 3 characters are required"
                // }
            }
        },
        username: {
            errors: [],
            value: username,
            isValid: null,
            validations: {
                required: "User Name is required",
                maxLength: {
                    value: 150,
                    error: "User Name cannot be more than 150 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "User Name at least 5 characters are required"
                // }
            }
        },
        email: {
            errors: [],
            value: email,
            isValid: null,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 60,
                    error: "Email address cannot be more than 60 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid email address"
            }
        },
        password: {
            errors: [],
            value: password,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Password is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Password cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Password: at least 6 characters are required",
                // },
            },
        },
        comfirm_password: {
            errors: [],
            value: password2,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Confirmation password is required"
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Confirmation password cannot be more than 15 characters"
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Confirmation password: at least 6 characters are required"
                // },
                sameAs: {
                    value: 'password',
                    error: "Confirmation password: the two passwords are not the same"
                }
            }
        },
        state: {
            errors: [],
            value: state,
            isValid: null,
            validations: {
                required: "State is required"
            }
        },
        city: {
            errors: [],
            value: city,
            isValid: null,
            validations: {
                required: "City is required"
            }
        },
        zipcode: {
            errors: [],
            value: zipcode,
            isValid: null,
            validations: {
                required: "Zipcode is required"
            }
        }
    }
}