import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import TruckIcon from "../icons/Truck";
import { Utils } from "../../utils";
import ReactTimeAgo from 'react-time-ago';
import labData from "../../utils/labData";
import ChevronRightIcon from "../icons/ChevronRight";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { rejectCommunityRequest } from "../../core/communities";

const RejectRequestModal = ({ isOpen, closeFun, request = null, onSuccess = null }) => {


    const cancelButtonRef = useRef(null);
    if (typeof onSuccess !== "function") {
        onSuccess = (x) => { return x; }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [reason, setReason] = useState("");

    const handleReject = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: request._id,
            reason
        };
        let response = null;
        //normal post
        response = await rejectCommunityRequest(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        closeFun(false);
        onSuccess(response);
    }

    const clearForm = () => {
        setReason("");
        setErrors([]);
    };

    useEffect(() => {
        clearForm();
    }, [request, isOpen]);


    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isOpen} onClose={closeFun}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                <div className="bg-white mx-4 rounded-md">

                                    <div className="bg-primary-white flex flex-row justify-between p-3 rounded-t-md items-center ">
                                        <div className="text-gray-900 text-md font-bold">
                                            Reject Community Request
                                        </div>
                                        <div className="flex flex-row items-center">

                                            <div className="ml-4 mr-1 text-gray-900 font-bold cursor-pointer hover:text-gray-800" onClick={() => closeFun(false)}>
                                                X
                                            </div>
                                        </div>

                                    </div>

                                    <div className="max-h-[90%] overflow-x-auto rounded-b-md">

                                        <form className="bg-white drop-shadow-md rounded-b-md">
                                            <div className="space-y-12 rounded-b-md">
                                                <div className="border border-gray-900/10 rounded-t-md ">
                                                    <div className="px-4 mt-4">
                                                        <span className="text-xl font-bold">{request?.title}</span>
                                                    </div>
                                                    <div className="py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6  ">
                                                        <div className="col-span-full">
                                                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                                <b>Provide A Reason For Rejecting This Request</b>
                                                                <span className="text-red-900 mx-1">*</span>
                                                            </label>
                                                            <div className="mt-2">
                                                                <textarea
                                                                    name="about"
                                                                    id="about"
                                                                    required
                                                                    value={reason}
                                                                    disabled={isLoading}
                                                                    onChange={(e) => setReason(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex  rounded-b-md flex-row items-center justify-between ">
                                                        <div className="app-error text-red-400 font-bold text-xs ">
                                                            {errors.length > 0 &&
                                                                <span>{errors[0]}</span>
                                                            }
                                                        </div>
                                                        <button role="button" 
                                                            type="button"
                                                            disabled={isLoading}
                                                            className="w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                                                            onClick={handleReject}
                                                        >
                                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                            Submit
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};



export default RejectRequestModal;