export const sendMessageRules = (inputs) => {
    return {
        message_text: {
            errors: [],
            value: inputs.message_text,
            isValid: null,
            validations: {
                maxLength: {
                    value: 500,
                    error: "Message text cannot be more than 500 characters"
                }
            }
        }
    }
};
