export const postCommentRules = (inputs) => {
    return {
        commentDetails: {
            errors: [],
            value: inputs.commentDetails,
            isValid: null,
            validations: {
                required: {
                    error: "Comment is required",
                    trim: true
                },
                // minLength: {
                //     value: 5,
                //     error: "Comment cannot be less than 5 characters",
                //     trim: true
                // },
                // maxLength: {
                //     value: 10000,
                //     error: "Comment cannot be more than 10,000 characters"
                // }
            }
        },
    }
}

export const updateCommentRules = (inputs) => {
    return {
        commentDetails: {
            errors: [],
            value: inputs.commentDetails,
            isValid: null,
            validations: {
                required: {
                    error: "Comment is required",
                    trim: true
                },
                // minLength: {
                //     value: 5,
                //     error: "Comment cannot be less than 5 characters",
                //     trim: true
                // },
                // maxLength: {
                //     value: 10000,
                //     error: "Comment cannot be more than 10,000 characters"
                // }
            }
        },
    }
}