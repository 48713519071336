import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/communities";
import { getJobListing } from "../core/job_listing";
import MainLayout from "../components/layouts/MainLayout";


const Home = () => {

    const [ads, setAds] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [jobListing, setJobListing] = useState(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const fetchCommunities = async () => {
        setIsLoading(true);

        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
            setCommunityBudges(communityBudges);
        } else {
            communityBudges = await getAnonymousCommunityBudges();
            setCommunityBudges(communityBudges);
        }

        const data = await getCommunities();
        const unsortedCommunitiesData = data?.communities ?? [];
        const ads = data?.ads ?? [];
        //the generally
        let sortedData = [];
        unsortedCommunitiesData.forEach((community) => {
            //skip the system community
            if (!community.is_system_community) {
                let forums = community?.forums ?? [];
                forums.sort((a, b) => {
                    const aTitle = a?.title ?? "";
                    const bTitle = b?.title ?? "";
                    return aTitle.localeCompare(bTitle);
                });
                sortedData.push({
                    ...community,
                    forums
                });
            }
        })
        setCommunities(sortedData);
        setAds(ads);
        setIsLoading(false);
    }

    useEffect(() => {

        fetchCommunities();

    }, []);

    const onPinCommunityChanged = async () => {
        fetchCommunities();
    }


    return (
        <MainLayout currentPage={"forums"} ads={ads} >
            <div className="flex-grow flex flex-col  ">
                <div className="flex flex-col gap-y-2 ">

                    {isLoading &&
                        <>
                            <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                            <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                            <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                        </>
                    }

                    {/* {jobListing &&
                            <JobListingCommunityAccordion
                                community={jobListing}
                                isOpenInitially={true}
                                key={'jobslisting-community'}
                                limit={5}
                            />
                        } */}


                    {communities.map((community, index) => {
                        //the public only sees communities with forums
                        if (community?.forums?.length === 0 && loggedInUser?.role === "admin") {
                            return <CommunityAccordion
                                community={community}
                                isOpenInitially={true}
                                key={community._id}
                                role={loggedInUser?.role}
                                position={index + 1}
                                onPinCommunityChanged={onPinCommunityChanged}
                                communityBudges={communityBudges}
                            />
                        } else if (community?.forums?.length > 0) {
                            return <CommunityAccordion
                                community={community}
                                isOpenInitially={true}
                                key={community._id}
                                role={loggedInUser?.role}
                                position={index + 1}
                                onPinCommunityChanged={onPinCommunityChanged}
                                communityBudges={communityBudges}
                            />
                        } else {
                            return <div key={community._id}></div>
                        }
                    })}
                </div>
            </div>
        </MainLayout>
    );
};

export default Home;

