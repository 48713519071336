export const registerRules = (inputs) => {
    let {
        avatar,
        firstName,
        lastName,
        username,
        email,
        password,
        password2,
        state,
        city,
        zipcode,
        role,
        companyLogoUrl,
        companyName,
        companyContact,
        companyAddress
    } = inputs;
    let rules = {
        username: {
            errors: [],
            value: username,
            isValid: null,
            validations: {
                required: "Screen Name is required",
                maxLength: {
                    value: 100,
                    error: "Screen Name cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 1,
                //     error: "User Name at least 1 letter is required"
                // }
            }
        },
        email: {
            errors: [],
            value: email,
            isValid: null,
            validations: {
                required: "Email address is required",
                maxLength: {
                    value: 100,
                    error: "Email address cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 5,
                //     error: "Email address: at least 5 characters are required"
                // },
                emailFormat: "Invalid email address"
            }
        },
        password: {
            errors: [],
            value: password,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Password is required",
                },
                maxLength: {
                    value: 100,
                    trim: false,
                    error: "Password cannot be more than 100 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Password: should be more than 5 characters",
                // },
            },
        },
        comfirm_password: {
            errors: [],
            value: password2,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Confirmation password is required"
                },
                maxLength: {
                    value: 100,
                    trim: false,
                    error: "Confirmation password cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Confirmation password: at least 6 characters are required"
                // },
                sameAs: {
                    value: 'password',
                    error: "Confirmation password: the two passwords are not the same"
                }
            }
        },
        state: {
            errors: [],
            value: state,
            isValid: null,
            validations: {
                required: "State is required"
            }
        },
        city: {
            errors: [],
            value: city,
            isValid: null,
            validations: {
                required: "City is required",
                alphaOnly: {
                    error: "City: numbers are not accepted",
                    trim: true,
                }  
            }
        },
        zipcode: {
            errors: [],
            value: zipcode,
            isValid: null,
            validations: {
                required: "Zipcode is required",
                length: {
                    value: 5,
                    error: "Zipcode: must be five numbers"
                },
                digitsOnly: "Zipcode: Only numbers are accepted"
            }
        }
    };
    if(role == "organisation"){
        rules = {
            companyName: {
                errors: [],
                value: companyName,
                isValid: null,
                validations: {
                    required: "Company name is required",
                    maxLength: {
                        value: 100,
                        error: "Company name: cannot be more than 100 characters"
                    }
                }
            },
            contact_person_name: {
                errors: [],
                value: inputs.contact_person_name,
                isValid: null,
                validations: {
                    required: "Contact person is required",
                    maxLength: {
                        value: 100,
                        error: "Contact person cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "Contact person at least 3 characters are required"
                    // }
                }
            },
            email: {
                errors: [],
                value: email,
                isValid: null,
                validations: {
                    required: "Email address is required",
                    maxLength: {
                        value: 100,
                        error: "Email address cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 5,
                    //     error: "Email address: at least 5 characters are required"
                    // },
                    emailFormat: "Invalid email address"
                }
            },
            password: {
                errors: [],
                value: password,
                isValid: null,
                validations: {
                    required: {
                        trim: false,
                        error: "Password is required",
                    },
                    maxLength: {
                        value: 100,
                        trim: false,
                        error: "Password cannot be more than 100 characters",
                    },
                    // minLength: {
                    //     value: 6,
                    //     trim: false,
                    //     error: "Password: should be more than 5 characters",
                    // },
                },
            },
            comfirm_password: {
                errors: [],
                value: password2,
                isValid: null,
                validations: {
                    required: {
                        trim: false,
                        error: "Confirmation password is required"
                    },
                    maxLength: {
                        value: 100,
                        trim: false,
                        error: "Confirmation password cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 6,
                    //     trim: false,
                    //     error: "Confirmation password: at least 6 characters are required"
                    // },
                    sameAs: {
                        value: 'password',
                        error: "Confirmation password: the two passwords are not the same"
                    }
                }
            },
            companyContact: {
                errors: [],
                value: companyContact,
                isValid: null,
                validations: {
                    required: "Company phone is required",
                    northAmericaPhoneFormat: "Company phone: please use recommended format 000-000-0000"
                }
            },
            companyAddress: {
                errors: [],
                value: companyAddress,
                isValid: null,
                validations: {
                    required: "Company address is required",
                    maxLength: {
                        value: 100,
                        error: "Company address: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 1,
                    //     error: "Company address: at least 1 characters is required"
                    // }
                }
            },
            city: {
                errors: [],
                value: city,
                isValid: null,
                validations: {
                    required: "City is required",
                    alphaOnly: {
                        error: "City: numbers are not accepted",
                        trim: true,
                    }  
                }
            },
            state: {
                errors: [],
                value: state,
                isValid: null,
                validations: {
                    required: "State is required"
                }
            },
            zipcode: {
                errors: [],
                value: zipcode,
                isValid: null,
                validations: {
                    required: "Zipcode is required",
                    length: {
                        value: 5,
                        error: "Zipcode: must be five numbers"
                    },
                    digitsOnly: "Zipcode: Only numbers are accepted"
                }
            }
        };
    }else{
        rules = {
            ... rules,
            firstName: {
                errors: [],
                value: firstName,
                isValid: null,
                validations: {
                    required: {
                        error: "First name is required",
                        trim: true
                    },
                    maxLength: {
                        value: 100,
                        error: "Cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "At least 3 characters are required"
                    // }
                }
            },
            lastName: {
                errors: [],
                value: lastName,
                isValid: null,
                validations: {
                    required: {
                        error: "Last name is required",
                        trim: true
                    },
                    maxLength: {
                        value: 100,
                        error: "Cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "At least 3 characters are required"
                    // }
                }
            }
        };
    }
    return rules;
}

export const updateProfileRules = (inputs) => {
    let {
        avatar,
        firstName,
        lastName,
        username,
        state,
        city,
        zipcode,
        companyLogoUrl,
        companyName,
        companyContact,
        companyAddress,
        role
    } = inputs;
    let rules = {
        username: {
            errors: [],
            value: username,
            isValid: null,
            validations: {
                required: "User Name is required",
                maxLength: {
                    value: 100,
                    error: "User Name cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 1,
                //     error: "User Name at least 1 letter is required"
                // }
            }
        },
        state: {
            errors: [],
            value: state,
            isValid: null,
            validations: {
                required: "State is required"
            }
        },
        city: {
            errors: [],
            value: city,
            isValid: null,
            validations: {
                required: "City is required",
                alphaOnly: "City: Numbers are not allowed"
            }
        },
        zipcode: {
            errors: [],
            value: zipcode,
            isValid: null,
            validations: {
                required: "Zipcode is required",
                length: {
                    value: 5,
                    error: "Zipcode: must be five numbers"
                },
                digitsOnly: "Zipcode: Only numbers accepted"
            }
        }
    };
    if(role == "organisation"){
        rules = {
            ... rules,
            companyName: {
                errors: [],
                value: companyName,
                isValid: null,
                validations: {
                    required: "Company name is required",
                    maxLength: {
                        value: 100,
                        error: "Company name: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "Company name: at least 3 characters are required"
                    // }
                }
            },
            companyAddress: {
                errors: [],
                value: companyAddress,
                isValid: null,
                validations: {
                    required: "Company address is required",
                    maxLength: {
                        value: 100,
                        error: "Company address: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 1,
                    //     error: "Company address: at least 1 characters is required"
                    // }
                }
            },
            companyContact: {
                errors: [],
                value: companyContact,
                isValid: null,
                validations: {
                    required: "Company contact is required",
                    maxLength: {
                        value: 100,
                        error: "Company contact: cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 7,
                    //     error: "Company contact: at least 7 characters are required (Phone Number or Email)"
                    // }
                }
            },
            
        };
    }else{
        rules = {
            ... rules,
            firstName: {
                errors: [],
                value: firstName,
                isValid: null,
                validations: {
                    required: "First name is required",
                    maxLength: {
                        value: 100,
                        error: "Cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "At least 3 characters are required"
                    // }
                }
            },
            lastName: {
                errors: [],
                value: lastName,
                isValid: null,
                validations: {
                    required: "Last name is required",
                    maxLength: {
                        value: 100,
                        error: "Cannot be more than 100 characters"
                    },
                    // minLength: {
                    //     value: 3,
                    //     error: "At least 3 characters are required"
                    // }
                }
            }
        };
    }
    return rules;
}