import React from "react";

const Pinned = ({className}) => {
    const classes = `w-4 h-4 ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="currentColor" 
            className={classes}>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M15.113 3.21l.094 .083l5.5 5.5a1 1 0 0 1 -1.175 1.59l-3.172 3.171l-1.424 3.797a1 1 0 0 1 -.158 .277l-.07 .08l-1.5 1.5a1 1 0 0 1 -1.32 .082l-.095 -.083l-2.793 -2.792l-3.793 3.792a1 1 0 0 1 -1.497 -1.32l.083 -.094l3.792 -3.793l-2.792 -2.793a1 1 0 0 1 -.083 -1.32l.083 -.094l1.5 -1.5a1 1 0 0 1 .258 -.187l.098 -.042l3.796 -1.425l3.171 -3.17a1 1 0 0 1 1.497 -1.26z" strokeWidth="0" fill="currentColor"></path>
        </svg>

        // <svg xmlns="http://www.w3.org/2000/svg"
        //     viewBox="0 0 24 24" 
        //      className={`icon icon-tabler icon-tabler-pin-filled ${classes}`}
             
        //      width="24" height="24"  strokeWidth="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        
        // </svg>
    );
}

export default Pinned;

