import React, { Fragment, useEffect, useRef, useState } from "react";

const LeaderBoardSkeleton = ({items=5}) => {
    return (
        <div role="status" className="flex flex-col mb-2 w-full animate-pulse bg-gray-100 mb-5">
            <div className="flex flex-row justify-center w-full p-2 gap-x-2 border-b border-gray-200 ">
                <div className="h-5 w-1/3 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            {[...Array(items)].map((e, i) => (
                <div key={i} className="flex flex-row w-full p-2 gap-x-2 border-b border-gray-200 justify-start">
                    <div className="flex h-16 w-16 min-w-16 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="flex flex-col  align-start justify-center">
                        <div className="h-3 w-14 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                        </div>
                        <div className="h-2 w-10 bg-gray-200 rounded-sm dark:bg-gray-700 mt-1 ">
                        </div>
                    </div>
                </div>
            ))}
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default LeaderBoardSkeleton;

