import React, { useState, useEffect, useRef } from "react";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import SingleTick from "../components/icons/SingleTick";
import { useNavigate } from "react-router-dom";
import { getUsersChatsList, searchUsersChatsList, sendUsersChatMessage } from "../core/chat";
import ChatListItemSkeleton from '../components/skeletons/ChatListItemSkeleton';
import NavUserAvatar from "../components/navigation/NavUserAvatar";
import ReactTimeAgo from 'react-time-ago';

const Chat = () => {

    const navigate = useNavigate();

    let { other_user_id } = useParams();

    if (!other_user_id) {
        other_user_id = "";
    }

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [chatsList, setChatsList] = useState([]);
    const [renderedChatList, setRenderedChatList] = useState([]);
    const [searchResultsList, setSearchResultsList] = useState([]);

    const [renderedChat, setRenderedChat] = useState(null);
    const [messageInputText, setMessageInputText] = useState("");
    const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
    const [sendMessageErrors, setSendMessageErrors] = useState([]);
    // Ref to target the chat div
    const chatContainerRef = useRef(null);


    const [searchChatListTerm, setSearchChatListTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    // Update debouncedTerm only after delay
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(searchChatListTerm);
        }, 500); // 500ms delay

        return () => {
            clearTimeout(timerId);
        };
    }, [searchChatListTerm]);
    // Trigger API call whenever debouncedTerm changes
    useEffect(() => {
        if (debouncedTerm) {
            fetchSearchResults(debouncedTerm);
        }
    }, [debouncedTerm]);
    // Fetch function (replace with your API call)
    const fetchSearchResults = async (term) => {
        if (term.length > 0) {
            setIsSearchLoading(true);
            setSearchResultsList([]);
            const data = await searchUsersChatsList({
                searchTerm: term
            });
            setIsSearchLoading(false);
            console.log("Search results:", data);
            setSearchResultsList([...data]);
        } else {
            //render back all the previous chats list before searching
            setSearchResultsList([]);
        }
    };

    const fetchData = async (withUserId) => {
        const data = await getUsersChatsList(withUserId);
        console.log("data", data); //{list: Array(0), un_seen_count: 0, with: null}
        setChatsList(data.list);
        setRenderedChatList(data.list);
        if (data.with !== null) {
            setRenderedChat(data.with)
            scrollChatIntoView();
        }
    }

    // chat box things
    const handleMessageInputChange = (event) => {
        setMessageInputText(event.target.value);
    };
    const handleMessageInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            // send the user a chat message
            sendMessage();
        }
    };
    const sendMessage = async () => {
        const trimmedMessage = messageInputText.trim();
        if (trimmedMessage.length > 0) {
            setSendMessageErrors([]);
            setIsSendMessageLoading(true);
            const data = await sendUsersChatMessage({
                message_text: trimmedMessage,
                other_user_id: other_user_id,
                media: ""
            });
            console.log("response", data);
            setIsSendMessageLoading(false)
            if (Array.isArray(data) && data.length > 0) {
                setSendMessageErrors(data);
            } else {
                setMessageInputText("");
                // insert the new message into the messages array
                setRenderedChat({
                    ...renderedChat,
                    messages: [
                        ...renderedChat.messages,
                        data
                    ]
                });
                setTimeout(() => {
                    scrollChatIntoView();
                }, 100);
            }
        }
    }

    const scrollChatIntoView = () => {
        // chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }

    useEffect(() => {
        if (loggedInUser === null || (loggedInUser !== null && loggedInUser._id == other_user_id)) {
            // Redirect to the communities page if the user is not logged in
            navigate(`/`);
        } else {
            fetchData(other_user_id);
        }
    }, []);

    return (
        <MainLayout currentPage={"chat"} showAsideLeft={false} >
            <div className="flex flex-row flex-1 gap-y-2 bg-[#f0f2f6] rounded-md h-[calc(100vh-65px)]">
                <div className="flex flex-col bg-white rounded-l-md w-[300px]">
                    <div className="flex flex-row w-full justify-between align-center pt-1 px-2">
                        <h1 className="text-md font-bold ">Chats</h1>
                    </div>
                    <div className="flex flex-col w-full justify-between align-center  px-2 pt-1">
                        <div className="flex w-full">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search"
                                    className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                    placeholder="Search"
                                    type="search"
                                    name="search"
                                    value={searchChatListTerm}
                                    onChange={(e) => setSearchChatListTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        {(isSearchLoading === false && searchChatListTerm.length > 0 && renderedChatList.length > 0) &&
                            <div className="text-xs pl-2 pt-1">
                                Found <b>{renderedChatList.length}</b> Result{renderedChatList.length != 1 ? 's' : ''}
                            </div>
                        }
                    </div>
                    <div className="flex flex-col p-2  max-w-full min-w-[275px] overflow-y-auto">

                        {/* start searching */}

                        {isSearchLoading &&
                            <>
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                            </>
                        }

                        {(isSearchLoading === false && searchChatListTerm.length > 0 && searchResultsList.length == 0) &&
                            <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                    <div className="text-center">
                                        No Search Results For <br />
                                        <small className="text-xs">{searchChatListTerm}</small>
                                    </div>
                                </div>
                            </div>
                        }

                        {searchChatListTerm.length > 0 && searchResultsList.map((user, index) => {
                            return (
                                <div key={index} className={`p-2 md:min-w-[120px] mr-2 even:bg-white odd:bg-slate-100 relative flex flex-row gap-x-2 md:mr-0 justify-start hover:bg-primary-gray-50 cursor-pointer`}>

                                    <NavUserAvatar user={user} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />


                                    <a href={`/chat/${user._id}`} className=" flex flex-col justify-between flex-grow ">
                                        <div className={`text-xs font-semibold leading-6x text-primary-gray-900`}>
                                            <div className="whitespace-nowrap ">
                                                <div>{user?.username ?? ''}</div>
                                                {/* <div className="absolute inset-x-2 top-[4px] bottom-0 font-bold w-fit" >
                                            {index+1}
                                        </div> */}
                                            </div>
                                        </div>
                                        <div className={`mt-1 text-xs flex leading-1 text-gray-500 whitespace-nowrap`}>
                                            member since {user?.join_date?.split("T")[0] ?? ''}
                                        </div>
                                    </a>
                                </div>
                            )
                        })}

                        {/* end searching */}

                        {(isSearchLoading === false && searchChatListTerm.length === 0) && renderedChatList.map((chatListItem, index) => {
                            const isActive = chatListItem.other_user._id == other_user_id || chatListItem.user._id == other_user_id;
                            let peerUser = null;
                            if (chatListItem.other_user._id == loggedInUser._id) {
                                // here loggedInUser was the other_user
                                peerUser = chatListItem.user;
                            } else {
                                peerUser = chatListItem.other_user;
                            }
                            return (
                                <div className={"flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer pl-2  hover:bg-purple-100 " + (isActive ? 'bg-purple-200' : 'even:bg-white odd:bg-slate-100')}>
                                    {/* <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                        <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover"
                                            style={{ backgroundImage: "url('https://res.cloudinary.com/dbjx623yn/image/upload/v1703093120/wefayo-app-image-uploads/cqn3nq0vgl7ml7nitbjv.jpg')" }} >
                                        </div>
                                    </div> */}

                                    <NavUserAvatar user={peerUser} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />

                                    <a href={`/chat/${peerUser._id}`} className="min-h-full  flex flex-col justify-between flex-grow border-b border-gray-300 pr-2 py-2 relative">
                                        <div className="flex flex-row justify-between items-center">
                                            <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap w-[120px]">{peerUser.username}</span>
                                            <div className="flex flex-row gap-x-1">
                                                <span className="text-xs text-gray-700  whitespace-nowrap text-nowrap">
                                                    <ReactTimeAgo date={chatListItem.last_updated_date} locale="en-US" />
                                                </span>
                                                {chatListItem.un_seen_count > 0 &&
                                                    <div className={`w-fit absolute bg-red-600 h-[15px] flex items-center text-white rounded-full right-[0px] -top-[-35px] p-1`} style={{ fontSize: "8px" }} >
                                                        {chatListItem.un_seen_count}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-start items-center pt-1 gap-x-1">
                                            {/* <SingleTick className="w-3 pt-[2px]" /> */}
                                            <div className="overflow-hidden whitespace-nowrap text-nowrap text-sm w-[220px]" >
                                                {chatListItem.last_message_text}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}

                        {(searchChatListTerm.length === 0 && renderedChatList.length == 0) &&

                            <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                    <div className="text-center">
                                        No Chats <br />
                                        <small>Get Started By Searching</small>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex flex-col flex-1 rounded-r-md ">

                    {renderedChat === null &&
                        <div className="flex flex-col h-full w-full ">
                            <div className="bg-[#f0f2f5] max-w-full rounded-r-md p-2 flex flex-row justify-between items-center">
                                {/* selected user */}
                                <div className="flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer ">
                                    <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                        <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover bg-gray-200" >

                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between flex-grow gap-y-2">
                                        <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap min-w-[350px] w-[350px] h-4 bg-gray-200"></span>
                                        <div className="flex flex-row gap-x-1 ">
                                            <span className="text-xs text-gray-700 min-w-[150px] w-[150px] h-4 bg-gray-200 ">

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* search chat */}
                                <div className="hidden flex w-[300px]">
                                    {/*    this is hidden for now
                                    
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                            onKeyDown={}
                                            onChange={}
                                            value={}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="flex-1 p-2 bg-[#efeae2] w-full">

                                <div className="w-full flex justify-end px-4 mt-2">
                                    <div className="flex flex-col bg-[#d7d3cb]/40 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 text-[#d9fdd2] h-20 w-[500px]"></div>
                                        <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1 h-2 w-[500px]"></span>
                                    </div>
                                </div>

                                <div className="w-full flex justify-start px-4 mt-2">
                                    <div className="flex flex-col bg-white/50 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 h-20 w-[500px]"></div>
                                        <div className="text-xs self-end  text-gray-400 h-2 w-[500px]"> </div>
                                    </div>
                                </div>

                                <div className="w-full text-center p-10 font-semibold text-gray-400">
                                    No Chat Selected
                                </div>

                                <div className="w-full flex justify-start px-4 mt-2">
                                    <div className="flex flex-col bg-white/50 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 h-20 w-[500px]">
                                        </div>
                                        <div className="text-xs self-end  text-gray-400 h-2 w-[500px]"></div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end px-4 mt-2">
                                    <div className="flex flex-col bg-[#d7d3cb]/40 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 text-[#d9fdd2] h-20 w-[500px]"></div>
                                        <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1 h-2 w-[500px]"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#f0f2f5] rounded-br-md flex flex-row items-center justify-center px-4">
                                <div className="flex-grow p-2 flex flex-row items-center justify-center ">
                                    <input
                                        type="text"
                                        name="message-input"
                                        id="message-input"
                                        autoComplete="message-input"
                                        disabled={true}
                                        placeholder="Type a message"
                                        className="mb-0 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-900"
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {renderedChat !== null && (() => {
                        let peerUser = null;
                        if (renderedChat.other_user._id == loggedInUser._id) {
                            // here loggedInUser was the other_user
                            peerUser = renderedChat.user;
                        } else {
                            peerUser = renderedChat.other_user;
                        }
                        return (
                            <div className="flex flex-col h-full w-full  " >
                                <div className="bg-[#f0f2f5] max-w-full rounded-r-md  p-2 flex flex-row justify-between items-center">
                                    {/* selected user */}
                                    <div className="flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer ">
                                        {/* <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                        <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover bg-gray-400"
                                            style={{ backgroundImage: "url('https://res.cloudinary.com/dbjx623yn/image/upload/v1703093120/wefayo-app-image-uploads/cqn3nq0vgl7ml7nitbjv.jpg')" }} >
                                        </div>
                                    </div> */}
                                        <NavUserAvatar user={peerUser} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />
                                        <div className="flex flex-col justify-between flex-grow">
                                            <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap w-[150px]">{peerUser.username}</span>
                                            <div className="flex flex-row gap-x-1">
                                                <span className="text-xs text-gray-700 ">
                                                    <ReactTimeAgo date={renderedChat.last_updated_date} locale="en-US" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* search chat */}
                                    <div className="hidden flex w-[300px]">
                                        {/*    this is hidden for now
                                    
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                            onKeyDown={}
                                            onChange={}
                                            value={}
                                        />
                                    </div> */}
                                    </div>
                                </div>
                                <div ref={chatContainerRef} className="flex-1 p-2 bg-[#efeae2] w-full overflow-y-auto">

                                    {renderedChat.messages.map((messageItem, index) => {
                                        const justify = messageItem.sender_id === loggedInUser._id ? 'justify-end' : 'justify-start';
                                        const bgColor = messageItem.sender_id === loggedInUser._id ? 'bg-[#d9fdd2]' : 'bg-white';
                                        return (
                                            <div className={`w-full flex px-4 mt-2 ${justify}`} key={'msg' + index}>
                                                <div className={`flex flex-col  p-1 max-w-[75%] rounded-md ${bgColor}`}>
                                                    <div className="p-1">{messageItem.message_text}</div>
                                                    <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1">
                                                        <ReactTimeAgo date={messageItem.send_date} locale="en-US" />
                                                        {/* <SingleTick className="w-3 pt-[2px]" /> */}
                                                    </span>
                                                </div>
                                            </div>)
                                    })}


                                </div>
                                <div className="bg-[#f0f2f5] rounded-br-md flex flex-col items-start justify-start px-4 relative">
                                    {sendMessageErrors.length > 0 &&
                                        <div className="app-error text-red-400 text-xs pt-2 pb-1 px-2 ">
                                            {sendMessageErrors[0]}
                                        </div>
                                    }
                                    <div className="flex-grow p-2 flex flex-row items-center justify-center w-full ">
                                        <textarea
                                            type="text"
                                            name="message-input"
                                            id="message-input"
                                            autoComplete="message-input"
                                            value={messageInputText}
                                            disabled={isLoading}
                                            placeholder="Type a message"
                                            onKeyDown={handleMessageInputKeyDown}
                                            onChange={handleMessageInputChange}
                                            className="mb-0 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        )
                    })()}


                </div>

            </div>
        </MainLayout>
    );
};

export default Chat;
