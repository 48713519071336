export const updatePasswordRules = ({ currentPassword, newPassword, comfirmPassword }) => {
    return {
        currentPassword: {
            errors: [],
            value: currentPassword,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Current passowrd is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Current password cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Current password: at least 6 characters are required",
                // },
            },
        },
        newPassword: {
            errors: [],
            value: newPassword,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "New passowrd is required",
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "New password cannot be more than 15 characters",
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "New password: at least 6 characters are required",
                // },
            },
        },
        comfirmPassword: {
            errors: [],
            value: comfirmPassword,
            isValid: null,
            validations: {
                required: {
                    trim: false,
                    error: "Confirmation password is required"
                },
                maxLength: {
                    value: 15,
                    trim: false,
                    error: "Confirmation password cannot be more than 15 characters"
                },
                // minLength: {
                //     value: 6,
                //     trim: false,
                //     error: "Confirmation password: at least 6 characters are required"
                // },
                sameAs: {
                    value: 'newPassword',
                    error: "Confirmation password: the two passwords are not the same"
                }
            }
        } 
    }
}

