export const trackApplyJobRules = ({jobId, userId}) => {
    return {
        userId: {
            errors: [],
            value: userId,
            isValid: null,
            validations: {
                required: "User Id is required",
                maxLength: {
                    value: 100,
                    error: "User Id cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 10,
                //     error: "User Id: at least 10 characters are required"
                // }
            }
        },
        jobId: {
            errors: [],
            value: jobId,
            isValid: null,
            validations: {
                required: "Job Id is required",
                maxLength: {
                    value: 100,
                    error: "Job Id cannot be more than 100 characters"
                },
                // minLength: {
                //     value: 10,
                //     error: "Job Id: at least 10 characters are required"
                // }
            }
        }
    }
}