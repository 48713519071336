import React, { useRef, useEffect, useState } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import { updateProfilePassword, getProfile } from "../core/auth";
import { Utils } from "../utils";
import toast, { Toaster } from 'react-hot-toast';
import Eye from "../components/icons/Eye";
import EyeSlash from "../components/icons/EyeSlash";
import MainLayout from "../components/layouts/MainLayout";


const ProfilePassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    loggedInUser = JSON.parse(loggedInUserStr);

    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(loggedInUser);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [password3, setPassword3] = useState("");

    const [errors, setErrors] = useState([]);
    const [passwordStrengthErrors, setPsswordStrengthErrors] = useState([]);
    const navigate = useNavigate();

    const clearForm = () => {
        setPassword("");
        setPassword2("");
        setPassword3("");
        setPsswordStrengthErrors([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        let inputs = {
            password2,
            password3,
            //current password
            password,
        };
        if (!checkPassword()) {
            setIsLoading(false);
            return false;
        }
        if (password === password2 || password === password3) {
            setIsLoading(false);
            setErrors(["Old and new password should be different"]);
            return false;
        }
        let response = await updateProfilePassword(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        toast.success('Your Password was updated successfully', {
            icon: '✅'
        });
        clearForm();
        navigate(`/profile/${loggedInUser._id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            const userProfile = await getProfile();
            setUser(userProfile);
        }
        fetchData();
    }, []);

    const checkPassword = () => {
        // check for password strength
        setPsswordStrengthErrors([]);
        const res = Utils.chaeckPasswordStrength(password2);
        if (res !== true) {
            setIsLoading(false);
            setPsswordStrengthErrors([res]);
            return false;
        }
        return true;
    }

    return (
        <MainLayout currentPage={"password"}  >
            <div className="mx-auto w-full relative rounded-md flex flex-col " >

                <div className="flex rounded-t-md justify-center bg-primary-white font-bold text-lg border-b border-gray-200 p-4">
                    <h5>Update Your Password</h5>
                </div>

                <div className="p-5 w-full flex flex-col rounded-b-md  bg-white">

                    <form method="POST" className="space-y-2" onSubmit={handleSubmit}>

                        <div className="flex flex-col md:w-2/3">
                            <label htmlFor="password2" className="block text-sm font-medium leading-6 text-gray-900">
                                New Password
                                <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                                <input
                                    id="password2"
                                    name="password2"
                                    type={`${showPassword ? "text" : 'password'}`}
                                    autoComplete=""
                                    required
                                    maxLength={100}
                                    value={password2}
                                    disabled={isLoading}
                                    onChange={(e) => setPassword2(e.target.value)}
                                    onBlur={(e) => checkPassword()}
                                    className="font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                />

                                <button role="button"
                                    onClick={(e) => setShowPassword(!showPassword)}
                                    type="button"
                                    className="absolute right-1 top-[5%] md:top-[14%] min-w-7 min-h-7 w-7 h-7  md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                                >
                                    {showPassword ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                                </button>
                            </div>
                        </div>

                        {passwordStrengthErrors.length > 0 &&
                            <div className="relative pt-4">
                                <div className="app-error text-red-400 text-xs absolute -top-[5px]">
                                    {passwordStrengthErrors[0]}
                                </div>
                            </div>
                        }


                        <div className="flex flex-col md:w-2/3">
                            <label htmlFor="password3" className="block text-sm font-medium leading-6 text-gray-900">
                                Enter New Password Again
                                <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                                <input
                                    id="password3"
                                    name="password3"
                                    type={`${showPassword2 ? "text" : 'password'}`}
                                    autoComplete=""
                                    required
                                    maxLength={100}
                                    value={password3}
                                    disabled={isLoading}
                                    onChange={(e) => setPassword3(e.target.value)}
                                    className="font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                />

                                <button role="button"
                                    onClick={(e) => setShowPassword2(!showPassword2)}
                                    type="button"
                                    className="absolute right-1 top-[5%] md:top-[14%] w-7 h-7  md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                                >
                                    {showPassword2 ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col md:w-2/3 pt-5">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Current Account Password
                                <span className="text-red-900 mx-1">*</span>
                            </label>
                            <div className="mt-0 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={`${showPassword3 ? "text" : 'password'}`}
                                    autoComplete=""
                                    required
                                    maxLength={100}
                                    value={password}
                                    disabled={isLoading}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                />

                                <button role="button"
                                    onClick={(e) => setShowPassword3(!showPassword3)}
                                    type="button"
                                    className="absolute right-1 top-[5%] md:top-[14%] w-7 h-7  md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                                >
                                    {showPassword3 ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                                </button>
                            </div>
                        </div>



                        <div className="pt-10 justify-between flex ">
                            <a
                                href="/profile"
                                className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                            >
                                Cancel
                            </a>


                            <button role="button"
                                type="submit"
                                disabled={isLoading || passwordStrengthErrors.length > 0}
                                className="flex justify-center items-center flex-row w-1/2 md:w-1/3 rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                            >
                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                Save Changes
                            </button>
                        </div>
                    </form>
                    {errors.length > 0 &&
                        <div className="app-error text-red-400 font-bold text-xs py-1">
                            {errors[0]}
                        </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default ProfilePassword;
