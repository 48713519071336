import React from "react";

const EnvelopeOpen = ({fillColor, className, strokeColor}) => {
    const fill = (fillColor)?fillColor:  "none";
    const stroke = (strokeColor)?strokeColor:  "currentColor";
    let classes = `w-4 h-4`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            fill={fill}
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke={stroke}
            className={classes}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
        </svg>
    );
}

export default EnvelopeOpen;




