import React, { Fragment, useEffect, useRef, useState } from "react";
import MagnifyingGlass from "../icons/MagnifyingGlass";

const JobsDetailsSkeleton = ({ items = 5 }) => {
    return (
        <div role="status" className="flex flex-col mb-2 w-full animate-pulse bg-gray-100 mb-5">
            <div className="flex flex-row w-full justify-start p-4 ">
                <div className="h-8 w-3/4 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            <div className="flex flex-row w-full p-4 gap-x-2 border-b border-gray-200 pb-5">
                <div className="h-16 w-16 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
                <div className="flex flex-row flex-1 justify-between ">
                    <div className="flex flex-col justify-between pt-2">
                        <div className="h-2 w-24 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                        </div>
                        <div className="h-3 w-48 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                        </div>
                        <div className="h-3 w-20 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                        </div>
                        <div className="flex flex-row gap-x-4">
                            <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                            <div className="h-2 w-5 bg-gray-200 rounded-sm dark:bg-gray-700 ">
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between items-end pt-3 ">
                        <div className="h-3 w-10 bg-gray-200 rounded-sm dark:bg-gray-700  ">
                        </div>
                        <div className="flex flex-row gap-x-4 pt-1 justify-end">
                            <div className="h-5 w-20 bg-gray-200 rounded-full dark:bg-gray-700 ">
                            </div>
                            <div className="h-5 w-20 bg-gray-200 rounded-full dark:bg-gray-700 ">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full p-4 gap-x-2 border-b border-gray-200 justify-start pt-5">
                <div className="flex flex-row justify-between">
                    <div className="flex h-3 w-24  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    
                </div>
                <div className="flex flex-col mt-5 gap-y-3">
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-2/3  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                </div>
                <div className="flex flex-col mt-5 gap-y-3">
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-1/3  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-1/2  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-1/2  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-1/2  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                </div>

                <div className="flex flex-col mt-5 gap-y-3">
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-full  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                    <div className="h-3 w-2/3  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                    </div>
                </div>

                
            </div>
            <div className="flex flex-row w-full p-4 gap-x-2 border-b border-gray-200 justify-start">
                
                <div className="h-8 w-24  bg-gray-200 rounded-sm dark:bg-gray-700 ">
                </div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default JobsDetailsSkeleton;

