import React from "react";

const OrderByDescending = ({fillColor, className, strokeColor}) => {
    const fill = (fillColor)?fillColor:  "currentColor";
    const stroke = (strokeColor)?strokeColor:  "currentColor";
    let classes = `w-6 h-6`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
       
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" />
        </svg>

    );
}

export default OrderByDescending;




