import { changeEmailRules } from "./changeEmailRules";

export const verifyNewEmailRules = ({currentEmail, newEmail, code}) => {
    const password = "";
    let changeRules = changeEmailRules({currentEmail, newEmail, password});
    return {
        currentEmail: {... changeRules.currentEmail },
        newEmail: { ... changeRules.newEmail} ,
        code: {
            errors: [],
            value: code,
            isValid: null,
            validations: {
              required: "Verification Code is required",
              length: {
                value: 6,
                error: "Verification Code: Expected a 6 digit code",
              },
                digitsOnly: "Verification Code: Only numbers are allowed",
            }
        }
    }
}