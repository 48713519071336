import React, { useRef, useEffect, useState } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useParams } from "react-router-dom";
import { addForumAd } from "../core/forum_ads";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import MainLayout from "../components/layouts/MainLayout";


const ForumAdsForm = () => {

    const navigate = useNavigate();

    let { id } = useParams();
    if(!id){
        id = "";
    }

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
        if (loggedInUser.role !== "admin") {
            navigate(`/login`);
        }
    }
    const userId = loggedInUser._id;

    //todo
    //if its not admin, go to the login page

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [forumId, setForumId] = useState(id);
    const [status, setStatus] = useState("active");
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [postedDate, setPostedDate] = useState(""); //new Date()
    const [expiryDate, setExpiryDate] = useState(""); //new Date()
    const [media, setMedia] = useState("");
    const [description, setDescription] = useState("");

    const clearForm = () => {
        setForumId(id);
        setStatus("active");
        setTitle("");
        setUrl("");
        setPostedDate(""); //new Date()
        setExpiryDate(""); //new Date()
        setMedia("");
        setDescription("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            userId,
            forumId,
            status,
            title,
            url,
            postedDate,
            expiryDate,
            media,
            description
        };
        let response = null;
        // console.log("inputs", inputs);
        response = await addForumAd(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('Forum ad was created successfully', {
            icon: '✅'
        });

        navigate(`/forumads`);
    }

    const DEFAULT_AD_MEDIA = "";
    const [isFileUpLoading, setIsFileUpLoading] = useState(false);
    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const handleOnMediaFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setMedia(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-avatar');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setMedia(data.secure_url);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    useEffect(() => {
        clearForm();
    }, []);

    return (
        <MainLayout currentPage={"forumads"} >
            <div className="flex-grow flex flex-col  ">
                <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md drop-shadow-sm mb-20 md:mb-0">
                    <div className="space-y-12">
                        <div className="border border-gray-900/10 rounded-md ">
                            <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0">{`${id ? 'Edit' : 'Post New'}`} Forum Ad</h2>
                            <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                Fill out and submit this form to {`${id ? 'edit a' : 'add a new'}`} forum side advert.
                            </p>

                            <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">

                                {/* forum id, status */}
                                <div className="flex flex-col md:flex-row gap-x-6 col-span-full">
                                    {/* forum id */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="forum-id" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Forum Id</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="forum-id"
                                                id="forum-id"
                                                autoComplete=""
                                                required
                                                value={forumId}
                                                disabled={true}
                                                onChange={(e) => setForumId(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    {/* Status */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="forum-status" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Status</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="forum-status"
                                                name="forum-status"
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                defaultValue="active"
                                                required
                                                value={status}
                                                disabled={isLoading}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value=""></option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* title */}
                                <div className="col-span-full">
                                    <label htmlFor="forum-title" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Title</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="forum-title"
                                            id="forum-title"
                                            autoComplete=""
                                            required
                                            value={title}
                                            disabled={isLoading}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        />
                                    </div>
                                </div>

                                {/* cta */}
                                <div className="col-span-full mt-4">
                                    <label htmlFor="forum-url" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Ad Url</b>
                                        <span className="text-red-900 mx-1">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="forum-url"
                                            id="forum-url"
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            required
                                            value={url}
                                            disabled={isLoading}
                                            onChange={(e) => setUrl(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* posted date, expiry date */}
                                <div className="flex flex-col md:flex-row gap-x-6 col-span-full">
                                    {/* posted date */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="forum-posted-date" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Posted Date</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="forum-posted-date"
                                                id="forum-posted-date"
                                                autoComplete=""
                                                required
                                                value={postedDate}
                                                disabled={isLoading}
                                                onChange={(e) => setPostedDate(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    {/* expiry date */}
                                    <div className="md:w-1/2">
                                        <label htmlFor="forum-expired-date" className="block text-sm font-medium leading-6 text-gray-900">
                                            <b>Expired Date</b>
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="forum-expired-date"
                                                id="forum-expired-date"
                                                autoComplete=""
                                                required
                                                value={expiryDate}
                                                disabled={isLoading}
                                                onChange={(e) => setExpiryDate(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* media */}
                                <div className="col-span-full">
                                    <div className="flex flex-row items-center justify-between w-full gap-x-3">
                                        <div className="flex flex-col pt-2">
                                            <img
                                                className="inline-block max-w-[150px] rounded-sm"
                                                src={media}
                                                alt=""
                                            />
                                        </div>
                                        <div className="mt-4 flex-grow flex flex-col items-start justify-start bg-grey-lighter pt-1">
                                            <label htmlFor="job-company-address" className="block flex flex-row justify-start items-start   text-sm font-medium leading-6 text-gray-900 mb-2">
                                                Media File
                                            </label>
                                            <label disabled={isLoading} htmlFor="select-avatar" className={"flex flex-row items-center px-3 py-1 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                </svg>
                                                <span className={`text-sm font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                    {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                    {(!media || media == DEFAULT_AD_MEDIA) ? 'Select' : 'Change'} Media File
                                                </span>
                                                <input id="select-avatar" disabled={isLoading} name="select-avatar" type='file' className="hidden" onChange={handleOnMediaFileChange} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* description */}
                                <div className="col-span-full my-5">
                                    <label htmlFor="ad-description" className="block text-sm font-medium leading-6 text-gray-900">
                                        <b>Description</b>
                                        <span className="text-red-900 mx-1">*</span>
                                        {/* <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                            - Provide details of the forum
                                        </span> */}
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            type="text"
                                            name="forum-description"
                                            id="forum-description"
                                            autoComplete=""
                                            required
                                            value={description}
                                            disabled={isLoading}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-center md:justify-end ">

                                <button role="button"
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full md:w-[200px] flex justify-center rounded-md bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                >
                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                    Submit
                                </button>

                            </div>

                            {errors.length > 0 &&
                                <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                    {errors[0]}
                                </div>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default ForumAdsForm;