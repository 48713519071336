import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import { uploadFileToCloudinary } from "../core";
import { verifyInviteParams, acceptInvitation, verifyJoinLink, joinViaLink } from "../core/invite";
import { Utils } from "../utils";
import states from "../utils/states.json";
import Eye from "../components/icons/Eye";
import EyeSlash from "../components/icons/EyeSlash";
import { logout } from "../core/auth";

export default function JoinViaLinkInvitation() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const bgVideo = "videos/bgvideo.mp4";
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const DEFAULT_AVATAR = process.env.REACT_APP_DEFAULT_AVATAR;
    const USE_DEFAULT_AVATAR = process.env.REACT_APP_USE_DEFAULT_AVATAR === "true";

    const [avatar, setAvatar] = useState(DEFAULT_AVATAR);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [code, setCode] = useState("");
    const [verifiedToken, setVerifiedToken] = useState("");
    const [invitationLink, setInvitationLink] = useState("");
    const [invitersName, setInvitersName] = useState("");
    const [passwordStrengthErrors, setPsswordStrengthErrors] = useState([]);

    const clearForm = () => {
        setAvatar(DEFAULT_AVATAR);
        setFirstName("");
        setLastName("");
        setUsername("");
        setEmail("");
        setCity("");
        setState("");
        setZipcode("");
        setPassword("");
        setPassword2("");
        setCode("");
        setVerifiedToken("");
        setPsswordStrengthErrors([]);
    };

    let isValidInvitation = true;
    let url = new URL(document.URL);
    let xhs = url.searchParams.get("xhs");
    let wiu = url.searchParams.get("wiu");
    let mb = url.searchParams.get("mb"); //encoded payload

    if (!xhs || !wiu || typeof xhs != 'string' || typeof wiu != 'string') {
        console.log("found here ");
        isValidInvitation = false;
    } else {
        xhs = xhs.replaceAll(" ", "+");
        wiu = wiu.replaceAll(" ", "+");
    }


    const [isValidPage, setIsValidPage] = useState(isValidInvitation);

    let isInvitationContext = typeof mb === 'string' ? true : false;

    const handleInvitationVerification = async () => {
        setErrors([]);
        setIsLoading(true);
        const inputs = { xhs, wiu };
        let response = null;
        const hrefOriginal = JSON.parse(JSON.stringify(window.location.href.toString()));
        setInvitationLink(hrefOriginal);
        if (isInvitationContext) {
            inputs['mb'] = mb;
            response = await verifyInviteParams(inputs);
        } else {
            response = await verifyJoinLink(inputs);
        }
        setIsLoading(false);
        if (Array.isArray(response)) {
            return setIsValidPage(false);
        }
        if (isInvitationContext) {
            //prefill email invitation form
            setEmail(response.email);
            setFirstName(response.firstName);
            setLastName(response.lastName);
            setUsername(response.username);
            setCode(response.code);
            setVerifiedToken(response.verifiedToken);
        }
        setInvitersName(response.invitersName);

        let fakeUrl = `/join?xhs=${xhs.slice(0, xhs.length / 4)}&wiu=${wiu.slice(0, wiu.length / 6)}`;
        if (isInvitationContext) {
            fakeUrl = `${fakeUrl}&mb=${mb.slice(0, mb.length / 10)}`;
        }
        window.history.replaceState(null, null, fakeUrl);
    };

    useEffect(() => {
        handleInvitationVerification();
    }, []);


    const handleOnFileChange = async (changeEvent) => {
        changeEvent.preventDefault();
        setIsLoading(true);
        uploadFileToCloudinary(changeEvent, (imageRespose) => {
            setIsLoading(false);
            setAvatar(imageRespose);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        let inputs = {
            avatar,
            firstName,
            lastName,
            username,
            email,
            password,
            password2,
            state,
            city,
            zipcode,
            invitationLink,
            code,
            verifiedToken
        };
        if (USE_DEFAULT_AVATAR === false && avatar === DEFAULT_AVATAR) {
            inputs.avatar = "";
        }

        if (!checkPassword()) {
            return false;
        }

        let response = null;
        if (isInvitationContext) {
            response = await acceptInvitation(inputs);
        } else {
            response = await joinViaLink(inputs);
        }
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();

        if (isInvitationContext) {
            //an email invitation is already a verified email so no need to 
            //send verification and activation code
            logout();
            navigate(`/`);
        } else {
            const encodedEmail = btoa(email);
            navigate(`/activate?mb=${encodedEmail}`);
        }
    };

    const checkPassword = () => {
        // check for password strength
        setPsswordStrengthErrors([]);
        const res = Utils.chaeckPasswordStrength(password);
        if (res !== true) {
            setIsLoading(false);
            setPsswordStrengthErrors([res]);
            return false;
        }
        return true;
    }


    return (
        <>
            <video
                className="hidden md:block absolute  w-auto min-w-full min-h-full max-w-screen "
                autoPlay
                muted
                loop
                src={bgVideo}></video>


            <div className="flex min-h-full flex-1 md:absolute">
                <div className="flex flex-1 flex-col min-h-full min-w-[100%] md:w-auto justify-center px-0 md:px-4 pb-12 lg:flex-none lg:px-20 xl:px-24 ">

                    <div className="md:mx-auto  h-full md:h-auto w-[100%] md:w-[550px] bg-primary-white px-[5px]  md:p-5 pt-5  relative rounded-sm md:shadow-md mt-0 md:mt-3">

                        <div className="flex flex-col ">

                            <div className="flex flex-row justify-center items-center gap-x-3 pt-5x">
                                <img
                                    className="h-10 w-auto"
                                    src="/logo.png"
                                    alt="wefayo organization"
                                />
                                <a href={isLoading ? '#' : '/'} className={"" + (isLoading ? "cursor-not-allowed" : "")}>
                                    <span className="text-4xl font-sans">
                                        <b className="font-sans">wefayo</b>
                                    </span>
                                </a>
                            </div>

                            {isValidPage &&
                                <h2 className="mt-1 text-xl text-center  font-bold leading-9 tracking-tight text-gray-900">
                                    <span className="text-gray-500">Register to join</span> {invitersName}
                                </h2>
                            }
                            {!isValidPage &&
                                <h2 className="mt-5 text-2xl text-center  font-bold leading-9 tracking-tight text-gray-900">
                                    🛑 Invalid Invitation Link !
                                </h2>
                            }
                        </div>

                        {/* Invitation is valid */}
                        {isValidPage &&
                            <div className="mt-5">
                                <div>
                                    <form method="POST" action="#" className="space-y-2 px-4 md:px-0" onSubmit={handleSubmit}>

                                        <div className="pb-3">

                                            <div className="mt-2 flex items-center w-full gap-x-3">

                                                <div className="flex flex-col ">
                                                    <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Avatar
                                                    </label>
                                                    <img
                                                        className="inline-block h-16 w-16 rounded-sm"
                                                        src={avatar}
                                                        alt=""
                                                    />
                                                </div>


                                                <div className="flex flex-col flex-grow self-stretch justify-end items-end">
                                                    <div className="flex items-center justify-center bg-grey-lighter">
                                                        <label disabled={isLoading} htmlFor="select-avatar" className={"flex flex-row items-center px-3 py-1 bg-white text-blue rounded-md shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                            <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                            </svg>
                                                            <span className="text-sm font-medium leading-6 text-gray-900 ">
                                                                {(!avatar || avatar == DEFAULT_AVATAR) ? 'Select' : 'Change'} profile photo
                                                            </span>
                                                            <input id="select-avatar" disabled={isLoading} name="select-avatar" type='file' className="hidden" onChange={handleOnFileChange} />
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex flex-col md:flex-row w-full gap-x-2 pt-2">
                                            <div className="flex flex-col flex-grow">
                                                <label htmlFor="firstname" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    First Name
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0">
                                                    <input
                                                        id="firstname"
                                                        name="firstname"
                                                        type="text"
                                                        autoComplete=""
                                                        required
                                                        maxLength={100}
                                                        value={firstName}
                                                        disabled={isLoading}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        className="app-sm-h app-mb-15 font-bold mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col  flex-grow">
                                                <label htmlFor="firstname" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    Last Name
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0">
                                                    <input
                                                        id="lastname"
                                                        name="lastname"
                                                        type="text"
                                                        autoComplete=""
                                                        required
                                                        maxLength={100}
                                                        value={lastName}
                                                        disabled={isLoading}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        className="app-sm-h app-mb-15 font-bold mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-grow mt-2 md:mt-0">
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email Address
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    value={email}
                                                    disabled={isInvitationContext ? true : (isLoading)}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col md:flex-row gap-x-2">
                                            <div className="flex-grow">
                                                <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    Password
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0 relative">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type={`${showPassword ? "text" : 'password'}`}
                                                        autoComplete="current-password"
                                                        required
                                                        maxLength={100}
                                                        value={password}
                                                        disabled={isLoading}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onBlur={(e) => checkPassword()}
                                                        className="app-sm-h app-mb-15 block w-full rounded-sm border-0 py-1 mb-1 md:mb-5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />
                                                    <button role="button" 
                                                        onClick={(e) => setShowPassword(!showPassword)}
                                                        type="button"
                                                        className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                                                    >
                                                        {showPassword ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="flex-grow  md:mt-0">
                                                <label htmlFor="password" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    Confirm Password
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0 relative">
                                                    <input
                                                        id="comfirm_password"
                                                        name="password"
                                                        type={`${showPassword2 ? "text" : 'password'}`}
                                                        autoComplete="current-password"
                                                        required
                                                        maxLength={100}
                                                        value={password2}
                                                        disabled={isLoading}
                                                        onChange={(e) => setPassword2(e.target.value)}
                                                        className="app-sm-h app-mb-15 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />

                                                    <button role="button" 
                                                        onClick={(e) => setShowPassword2(!showPassword2)}
                                                        type="button"
                                                        className="absolute right-1 top-[20%] md:top-[18%] w-8 h-8 md:w-6 md:h-6 flex justify-center items-center flex-row   text-sm text-gray-400  "
                                                    >
                                                        {showPassword2 ? <EyeSlash className={"w-7 h-7 md:w-6 md:h-6"} /> : <Eye className={"w-7 h-7 md:w-6 md:h-6"} />}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {passwordStrengthErrors.length > 0 &&
                                            <div className="relative pt-4">
                                                <div className="app-error text-red-400 text-xs absolute -top-[20px]">
                                                    {passwordStrengthErrors[0]}
                                                </div>
                                            </div>
                                        }

                                        <div>
                                            <label htmlFor="email" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                User Name
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <input
                                                    id="username"
                                                    name="username"
                                                    type="text"
                                                    autoComplete=""
                                                    required
                                                    maxLength={100}
                                                    value={username}
                                                    disabled={isLoading}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>


                                        <div className="flex flex-col flex-grow">
                                            <label htmlFor="city" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                City
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <input
                                                    id="city"
                                                    name="city"
                                                    type="text"
                                                    autoComplete=""
                                                    required
                                                    maxLength={100}
                                                    value={city}
                                                    disabled={isLoading}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    className="app-sm-h app-mb-15 font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-row w-full gap-x-2 pb-2">

                                            <div className="flex flex-col flex-grow">
                                                <label htmlFor="state" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    State
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0">
                                                    <select
                                                        id="state"
                                                        name="state"
                                                        type="text"
                                                        autoComplete=""
                                                        required
                                                        value={state}
                                                        disabled={isLoading}
                                                        onChange={(e) => setState(e.target.value)}
                                                        className="app-sm-h app-mb-15 font-bold first-option mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    >
                                                        <option value="" key="firstone" selected disabled >Select State</option>
                                                        {states.map((state) => {
                                                            return (
                                                                <option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex flex-col w-[100px]">
                                                <label htmlFor="zipcode" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    Zip Code
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="mt-0">
                                                    <input
                                                        id="zipcode"
                                                        name="zipcode"
                                                        type="text"
                                                        autoComplete=""
                                                        required
                                                        value={zipcode}
                                                        disabled={isLoading}
                                                        maxLength={5}
                                                        onChange={(e) => setZipcode(e.target.value)}
                                                        className="app-sm-h font-bold  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button role="button" 
                                                type="submit"
                                                disabled={isLoading || passwordStrengthErrors.length > 0}
                                                className="app-sm-h app-sm-fs w-full flex justify-center items-center flex-row flex-1 rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                            >
                                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                Signup
                                            </button>
                                        </div>
                                    </form>
                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-1">
                                            {errors[0]}
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {/* Invitation is valid */}
                        {!isValidPage &&
                            <div>
                                {typeof mb === 'string' &&
                                    <p className="p-5 bg-red-200 mt-10 text-xl">Please check your email address for an invitation to join <b className="text-red-900">wefayo</b> and click the <b className="text-red-900">Accept Invitation</b> button.</p>
                                }
                                {typeof mb !== 'string' &&

                                    <p className="p-5 bg-red-200 mt-10 text-xl">
                                        The link to join <b className="text-red-900">wefayo</b> has either been tampered with or is not valid <br />
                                        <div className="pt-2">
                                            <a className="text-purple-900 font-bold " href="/register">Click Here To Signup</a>
                                        </div>
                                    </p>
                                }
                                <div className="flex pt-5">
                                    <a
                                        href="mailto:support@wefayo.com"
                                        className="flex justify-center items-center flex-row w-full rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed "
                                    >
                                        Talk To Support
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="relative  flex-1 lg:block ">


                </div>

            </div>
        </>
    )
}




