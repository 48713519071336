import React from "react";

const Avatar = ({user}) => {
    const hasAvatar = user && user.avatar && user.avatar.trim().length > 0;
    return (
        <div>
            {hasAvatar && 
                <img
                    className="inline-block h-14 w-14 rounded-sm"
                    src={user.avatar}
                    alt=""
                />
            }
            {!hasAvatar && 
                <div className="flex items-center justify-center h-14 w-14 rounded-sm uppercase font-bold text-3xl font-sans"
                        style={{backgroundColor: user.name_initials_bg_color, color: user.name_initials_text_color}}
                    > 
                    {user.name[0]}
                </div>
            }
        </div>
    );
};

export default Avatar;