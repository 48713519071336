import React, { useEffect, useState, useMemo } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import ForumHeaderSection from "../components/forum/ForumHeaderSection";
import PostListItem from "../components/post/PostListItem";
import labData from "../utils/labData";
import Pagination from "../components/pagination/Pagination";
import Breadcrumb from "../components/navigation/Breadcrumb";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useParams } from "react-router-dom";
import TruckIcon from "../components/icons/Truck";
import { Utils } from "../utils";
import OrderByDescending from "../components/icons/OrderByDescending";
import OrderByAscending from "../components/icons/OrderByAscending";
import { getForum, getAnonymousCommunityBudges, getCommunityBudges, updateCommunitiesBudges } from "../core/communities";
import ForumSkeleton from "../components/skeletons/ForumSkeleton";
import Loader from 'react-loaders';
import ForumAvatar from "../components/forum/ForumAvatar";
import { useNavigate } from "react-router-dom";
import EditIcon from "../components/icons/Edit";
import AdIcon from "../components/icons/Ad";
import PlusCircle from "../components/icons/PlusCircle";
import ReactTimeAgo from 'react-time-ago';
import LoginAlertModal from "../components/auth/LoginAlertModal";
import MainLayout from "../components/layouts/MainLayout";


const Forum = () => {
    const { comid, id } = useParams();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [forum, setForum] = useState(null);
    const [postsToRender, setPostsToRender] = useState([]);
    const [isDescOrder, setIsDescOrder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOrderLoading, setIsOrderLoading] = useState(false);
    const [breadcrumbPages, setBreadcrumbPages] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    let role = "";
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
        role = loggedInUser.role;
    }


    const setPinnedPostsFirst = (posts) => {
        let pinned = [];
        let others = [];
        posts.forEach((post) => {
            if (post.is_pinned && post.is_pinned === true) {
                pinned.push(post);
            } else {
                others.push(post);
            }
        });
        return pinned.concat(others);
    }

    const fetchForum = async (isOrderChange) => {

        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
        } else {
            communityBudges = await getAnonymousCommunityBudges();
        }
        setCommunityBudges(communityBudges);

        if (isOrderChange === false) {
            setIsLoading(true);
        } else {
            setIsOrderLoading(true)
        }
        const order = isDescOrder ? "asc" : "desc";
        const data = await getForum(id, order);

        //order posts by pinned first: posts
        data.posts = setPinnedPostsFirst(data.posts);

        setForum(data);
        setIsLoading(false);
        setIsOrderLoading(false)
        setIsDescOrder(!isDescOrder);

        setBreadcrumbPages([
            { name: data?.community.title, href: `/`, current: false },
            { name: data?.title, href: `/community/${comid}/forum/${id}`, current: true },
        ]);


        //here update the forum budges tracking
        if (loggedInUser !== null) {
            updateCommunitiesBudges(id);
        } else {
            let localTrack = localStorage.getItem("forumsTracker");
            if (!localTrack) {
                localTrack = {};
            } else {
                localTrack = JSON.parse(localTrack);
            }
            localTrack[id] = (new Date()).toISOString();
            localStorage.setItem("forumsTracker", JSON.stringify(localTrack));
        }
    }

    useEffect(() => {
        fetchForum(false);
    }, []);


    let data = {
        length: forum ? forum.posts.length : 0
    };
    let PageSize = labData.pageSize;




    const handleChangeOrder = (e) => {
        e.preventDefault();
        fetchForum(true);
    }

    const handleAddNewPostClick = (e, comid, id) => {
        e.preventDefault();
        if (loggedInUserStr) {
            navigate(`/forum-post-form/${comid}/${id}`);
        } else {
            toggleLoginAlertModalModal();
            // navigate(`/login`);
        }
    }

    const onPinPostChanged = (postId) => {
        fetchForum();
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };



    return (
        <MainLayout currentPage={"forums"} ads={forum?.ads ?? []} >
            {isLoading &&
                <ForumSkeleton></ForumSkeleton>
            }

            {!isLoading &&
                <div className="flex-grow flex flex-col  ">
                    <LoginAlertModal message="Login to post." isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
                    <ForumHeaderSection
                        forum={forum}
                        breadcrumbPages={breadcrumbPages}
                    />
                    {forum && <>
                        <div className="flex flex-col bg-primary-white p-3 mb-0 border-b border-gray-100 w-full">
                            <div className="mt-1  flex flex-row">
                                <div className="min-w-[48px] min-h-[48px] w-[48px] h-[48px] bg-purple-800x flex justify-center items-center text-purple-300 font-bold">
                                    <ForumAvatar forum={forum} />
                                </div>
                                <div className="pl-2 flex flex-col">
                                    <div className="text-left text-xl font-bold text-gray-800 ">
                                        {forum.title}
                                    </div>
                                    {forum.description && forum.description.length > 0 &&
                                        <div className="text-left text-gray-600 text-sm font-normal">
                                            {forum.description}
                                        </div>
                                    }
                                    {forum?.latest_post &&
                                        <div className="text-left text-gray-600 text-sm font-sans">
                                            <span className="mr-1">Latest post </span>
                                            {/*by <a className="underline-offset-4 mr-1">{forum?.latest_post?.user.username},</a> */}
                                            <ReactTimeAgo date={forum?.latest_post?.date_of_post} locale="en-US" />
                                            {/* <span className="mr-3">,</span> on */}
                                            {/* <i className=" underline-offset-4 ml-1" >{Utils.formatDate(forum?.latest_post?.date_of_post, "month date, year")}</i> */}

                                            {/* <a
                                                href={`/community/${comid}/forum/${id}/post/${forum?.latest_post.post_id}`}
                                                className="underlinex text-purple-900  ml-1">
                                                - {forum?.latest_post?.title}
                                            </a> */}
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="flex justify-between mb-0  bg-white p-2 border-b border-gray-100 ">
                            <h1 className="font-bold text-md md:text-xl flex items-center">
                                <span className="isolate inline-flex rounded-sm ">
                                    <button role="button" aria-label="toggle order of posts ascending or descending" onClick={handleChangeOrder} type="button" className="relative inline-flex items-center rounded-sm bg-gray-300x px-0 md:px-2 mr-1 py-0.5 font-semibold text-purple-900 hover:bg-gray-200 ">
                                        {isDescOrder &&
                                            <OrderByDescending />
                                        }
                                        {!isDescOrder &&
                                            <OrderByAscending />
                                        }
                                    </button>
                                </span>
                                Posts

                            </h1>

                            <div className="flex flex-row">
                                <a
                                    onClick={(e) => handleAddNewPostClick(e, comid, id)}
                                    className="pointer flex flex-row gap-x-[2px] cursor-pointer rounded-sm text-purple-900 px-3 py-1.5 text-sm md:text-md font-bold hover:text-purple-800 "
                                >
                                    <PlusCircle />
                                    Create Post
                                </a>
                                {loggedInUser?.role === "admin" &&
                                    <>
                                        <a
                                            href={`/community/${comid}/forum/${id}/edit`}
                                            className="pointer flex flex-row gap-x-[2px]  cursor-pointer ml-2 rounded-sm text-purple-900 px-3 py-1.5 text-sm md:text-md font-bold hover:text-purple-800 "
                                        >
                                            <EditIcon />
                                            Edit
                                            <span className="hidden md:inline-block">Forum</span>
                                        </a>

                                        {/* <a
                                            href={`/forumads/add/${id}`}
                                            className="pointer flex flex-row items-center gap-x-[2px]  cursor-pointer ml-2 rounded-sm text-purple-900 px-3 py-1.5 text-sm md:text-md font-bold hover:text-purple-800 "
                                        >
                                            <AdIcon />
                                            Create Ad
                                        </a> */}
                                    </>
                                }
                            </div>

                        </div>

                        <div className="flex flex-col min-h-[55%]  items-start w-full ">
                            {isOrderLoading &&
                                <div className="w-full flex justify-center ">
                                    <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />
                                </div>
                            }

                            {forum.posts.map((post, index) => (
                                <PostListItem postBudges={communityBudges[id] ? communityBudges[id] : []} post={post} key={index} communityId={comid} forumId={id} onPinPostChanged={onPinPostChanged} role={role} />
                            ))}

                            {forum.posts.length == 0 &&
                                <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                    <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                        <div>
                                            No Posts
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="py-1 mb-5 text-sm ">
                            <Pagination
                                id="pgn-bottom"
                                currentPage={currentPage}
                                totalCount={data.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </>}
                </div>
            }
        </MainLayout>
    );
};

export default Forum;