import { verifyJoinLinkRules } from "./verifyJoinLinkRules"

export const verifyInviteRules = ({xhs, wiu, mb}) => {
    const linkRules = verifyJoinLinkRules({xhs, wiu});
    return {
        ... linkRules,
        mb: {
            errors: [],
            value: mb,
            isValid: null,
            validations: {
                required: "Invalid invite link: mb required",
                maxLength: {
                    value: 1500,
                    error: "Invalid invite link: mb too big",
                },
                minLength: {
                    value: 50,
                    error: "Invalid invite link: mb too small",
                }
            }
        }
    }
}